<script lang="ts">import { writable } from "svelte/store";
import "yinzcam-cards";
import "components/cards/common/CardsDataSourceRegistration";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle, addClassOnClickAction, anchorResolveUrlAction } from "components/cards/utilities";
import { ButtonParticle, ButtonParticleSpec, PageParticle, PageParticleSpec, SearchParticle, SearchParticleSpec } from "components/cards/particles/YinzCam";
import { getSignonManager } from "components/cards/atoms";
import { createEventDispatcher, onMount } from "svelte";
import { portal } from "svelte-portal/src/Portal.svelte";
export let element;
export let sources = writable();
export let sourceStore = undefined;
export let page = undefined;
export let opened = false;
export let openFullPage = false;
export let showCloseButton = false;
export let anchorContainer;
$: {
    sourceStore = getFirstSourceStore($sources);
}
;
$: maxWidth = getTemplatedElementDataFromSource(element, "maxWidth", $sourceStore);
$: menuPadding = getTemplatedElementDataFromSource(element, "menuPadding", $sourceStore);
$: menuFontSize = getTemplatedElementDataFromSource(element, "menuFontSize", $sourceStore);
$: menuFontColor = getTemplatedElementDataFromSource(element, "menuFontColor", $sourceStore);
$: menuFontWeight = getTemplatedElementDataFromSource(element, "menuFontWeight", $sourceStore);
$: menuBackgroundColor = getTemplatedElementDataFromSource(element, "menuBackgroundColor", $sourceStore);
$: menuBackgroundImage = getTemplatedElementDataFromSource(element, "menuBackgroundImage", $sourceStore);
$: menuBackgroundSize = getTemplatedElementDataFromSource(element, "menuBackgroundSize", $sourceStore);
$: menuBackgroundRepeat = getTemplatedElementDataFromSource(element, "menuBackgroundRepeat", $sourceStore);
$: menuBackgroundPosition = getTemplatedElementDataFromSource(element, "menuBackgroundPosition", $sourceStore);
$: menuItemPadding = getTemplatedElementDataFromSource(element, "menuItemPadding", $sourceStore);
$: menuItemDividerSize = getTemplatedElementDataFromSource(element, "menuItemDividerSize", $sourceStore);
$: menuItemDividerColor = getTemplatedElementDataFromSource(element, "menuItemDividerColor", $sourceStore);
$: menuItemHoverBackgroundColor = getTemplatedElementDataFromSource(element, "menuItemHoverBackgroundColor", $sourceStore);
$: menuItemHoverFontColor = getTemplatedElementDataFromSource(element, "menuItemHoverFontColor", $sourceStore);
$: menuItemClickedBackgroundColor = getTemplatedElementDataFromSource(element, "menuItemClickedBackgroundColor", $sourceStore);
$: menuItemClickedFontColor = getTemplatedElementDataFromSource(element, "menuItemClickedFontColor", $sourceStore);
$: submenuPadding = getTemplatedElementDataFromSource(element, "submenuPadding", $sourceStore);
$: submenuTitleFontSize = getTemplatedElementDataFromSource(element, "submenuTitleFontSize", $sourceStore);
$: submenuTitleFontColor = getTemplatedElementDataFromSource(element, "submenuTitleFontColor", $sourceStore);
$: submenuTitleFontWeight = getTemplatedElementDataFromSource(element, "submenuTitleFontWeight", $sourceStore);
$: submenuFontSize = getTemplatedElementDataFromSource(element, "submenuFontSize", $sourceStore);
$: submenuFontColor = getTemplatedElementDataFromSource(element, "submenuFontColor", $sourceStore);
$: submenuFontWeight = getTemplatedElementDataFromSource(element, "submenuFontWeight", $sourceStore);
$: submenuBackgroundColor = getTemplatedElementDataFromSource(element, "submenuBackgroundColor", $sourceStore);
$: submenuBackgroundImage = getTemplatedElementDataFromSource(element, "submenuBackgroundImage", $sourceStore);
$: submenuBackgroundSize = getTemplatedElementDataFromSource(element, "submenuBackgroundSize", $sourceStore);
$: submenuBackgroundRepeat = getTemplatedElementDataFromSource(element, "submenuBackgroundRepeat", $sourceStore);
$: submenuBackgroundPosition = getTemplatedElementDataFromSource(element, "submenuBackgroundPosition", $sourceStore);
$: submenuItemPadding = getTemplatedElementDataFromSource(element, "submenuItemPadding", $sourceStore);
$: submenuItemDividerSize = getTemplatedElementDataFromSource(element, "submenuItemDividerSize", $sourceStore);
$: submenuItemDividerColor = getTemplatedElementDataFromSource(element, "submenuItemDividerColor", $sourceStore);
$: submenuItemHoverBackgroundColor = getTemplatedElementDataFromSource(element, "submenuItemHoverBackgroundColor", $sourceStore);
$: submenuItemHoverFontColor = getTemplatedElementDataFromSource(element, "submenuItemHoverFontColor", $sourceStore);
$: items = getTemplatedElementDataFromSource(element, "items", $sourceStore) || [];
//$: hasItems = items && items.length > 0;
//$: console.log('HeaderNavFlyoutMenu ITEMS', items);
//$: linkUrl = resolveUrl(getTemplatedElementDataFromSource(element, "linkUrl", $sourceStore)?.toString()).href;
const dispatch = createEventDispatcher();
let topNav;
onMount(() => {
    const func = () => {
        console.log('navigate back handler');
        dispatch('navigateBack');
    };
    topNav.addEventListener('navigate-back', func);
    return () => {
        topNav === null || topNav === void 0 ? void 0 : topNav.removeEventListener('navigate-back', func);
    };
});
function handleCloseButtonClick() {
    dispatch('navigateBack');
}
const sso = getSignonManager();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
async function handleSignInButtonClick() {
    if (isLoggedIn) {
        sso.showCompleteProfilePage();
    }
    else {
        sso.requireAuth();
    }
}
let menuTopPx = 0;
$: if (opened && anchorContainer) {
    const bb = anchorContainer.getBoundingClientRect();
    menuTopPx = bb.top + bb.height;
}
$: style = buildInlineStyle("max-width", maxWidth, "menu-padding", menuPadding, "menu-font-size", menuFontSize, "menu-font-color", menuFontColor, "menu-font-weight", menuFontWeight, "menu-background-color", menuBackgroundColor, "menu-background-image", menuBackgroundImage, "menu-background-size", menuBackgroundSize, "menu-background-repeat", menuBackgroundRepeat, "menu-background-position", menuBackgroundPosition, "menu-item-padding", menuItemPadding, "menu-item-divider-size", menuItemDividerSize, "menu-item-divider-color", menuItemDividerColor, "menu-item-hover-background-color", menuItemHoverBackgroundColor, "menu-item-hover-font-color", menuItemHoverFontColor, "menu-item-clicked-background-color", menuItemClickedBackgroundColor, "menu-item-clicked-font-color", menuItemClickedFontColor, "submenu-padding", submenuPadding, "submenu-title-font-size", submenuTitleFontSize, "submenu-title-font-color", submenuTitleFontColor, "submenu-title-font-weight", submenuTitleFontWeight, "submenu-font-size", submenuFontSize, "submenu-font-color", submenuFontColor, "submenu-font-weight", submenuFontWeight, "submenu-background-color", submenuBackgroundColor, "submenu-background-image", submenuBackgroundImage, "submenu-background-size", submenuBackgroundSize, "submenu-background-repeat", submenuBackgroundRepeat, "submenu-background-position", submenuBackgroundPosition, "submenu-item-padding", submenuItemPadding, "submenu-item-divider-size", submenuItemDividerSize, "submenu-item-divider-color", submenuItemDividerColor, "submenu-item-hover-background-color", submenuItemHoverBackgroundColor, "submenu-item-hover-font-color", submenuItemHoverFontColor, "menu-top", `${menuTopPx}px`);
</script>

<nav bind:this={topNav} use:portal={"body"} class="primary-nav" class:has-items={!!items} class:opened={opened} class:full-page={openFullPage} on:mouseenter on:mouseleave on:mouseover on:focus {style}>
  {#if showCloseButton}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="modal-close-button" on:click={handleCloseButtonClick}>
    <svg viewBox="0 0 32 32">
      <use xlink:href={`#yc_icon_close`}></use>
    </svg>
  </div>
  {/if}
  {#if page}
  <PageParticle {...page} />
  {:else}
  <div class="top-controls">
    <SearchParticle {...SearchParticleSpec.propsWithDefaults({
      container: { themeMode: 'primary' },
      clearButton: { image: { iconColor: 'var(--theme-mode-accent-color)' }, text: { useAccentColor: true } }
    })}/>
    <ButtonParticle on:click={handleSignInButtonClick} {...ButtonParticleSpec.propsWithDefaults({
      container: { padding: '0.75rem 1.25rem', background: { visible: true }, border: { visible: true, radius: '0.375rem' } },
      text: { text: (isLoggedIn)? "Profile" : "Sign In", size: '1rem', weight: '600' },
      showImage: false,
      showText: true
    })} />
  </div>
  <ul class="primary-menu">
  {#each items as item}
    <li class="primary-menu-item" class:clicked={false} class:has-items={!!item.items} use:addClassOnClickAction={{cls: 'clicked', removeClassFromSiblings: true}}>
      <a href="#top" use:anchorResolveUrlAction={(item.items)? null : (item.linkUrl || null)}>
        {item.title}
      {#if item.subsections}
        <svg viewBox="0 0 32 32" class="arrow-right">
          <use xlink:href="#yc_icon_chevron_right"></use>
        </svg>
      {/if}
      </a>
      {#if item.subsections}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <nav class="secondary-nav" on:click|stopPropagation>
        <div class="back-link" on:click={function() {
          this.closest('.primary-menu-item').classList.remove('clicked');
        }}>
          <svg viewBox="0 0 32 32" class="arrow-left">
            <use xlink:href="#yc_icon_chevron_left"></use>
          </svg>
          {item.title}
        </div>
        {#if item.menuPage?.slug}
          <PageParticle {...PageParticleSpec.propsWithDefaults({
            slug: item.menuPage?.slug,
            pathParams: (item.slug)? [ item.slug ] : [],
          })}/>
        {:else}
          <ul class="secondary-menu">
            {#each item.subsections as subsection}
            <li class="secondary-menu-group">
              <ul class="secondary-menu-group">
              {#if subsection.title}
                <li class="group-title">{subsection.title}</li>
              {/if}
              {#each subsection.pages as page }
                <li class="secondary-menu-item">
                  <a href="#top" use:anchorResolveUrlAction={page.linkUrl || '#'}>{page.title}</a>
                </li>
              {/each}
              </ul>
            </li>
            {/each}
          </ul>
        {/if}
      </nav>
      {/if}
    </li>
  {/each}
  </ul>
  {/if}
</nav>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  nav.primary-nav {
    position: absolute;
    top: var(--menu-top, 100%);
    height: auto;
    right: 0;
    width: 100%;
    z-index: 10000;
    overflow: visible;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in-out;
    padding: var(--menu-padding, 0em);
    max-width: var(--max-width, unset);

    background-color: var(--menu-background-color, unset);
    background-image: var(--menu-background-image, unset);
    background-size: var(--menu-background-size, unset);
    background-repeat: var(--menu-background-repeat, unset);
    background-position: var(--menu-background-position, unset);
  }

  .modal-close-button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid var(--theme-primary-accent-color, black);
    border-radius: 50%;
    background-color: var(--theme-primary-background-color, white);
    z-index: 9999;
    cursor: pointer;
  }

  nav.primary-nav:not(.opened.full-page) > .modal-close-button {
    display: none;
  }

  .modal-close-button > svg {
    width: 100%;
    height: 100%;
    vertical-align: unset;
  }

  nav.primary-nav.opened {
    visibility: visible;
    opacity: 1;
  }

  nav.primary-nav.opened.full-page {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: auto;
  }

  .top-controls {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .primary-menu {
    color: var(--menu-font-color, unset);
    font-size: var(--menu-font-size, unset);
    font-weight: var(--menu-font-weight, unset);
    margin: 0;
    width: 100%;
    transition: width .2s ease-in-out
  }

  li.primary-menu-item {
    display: flex;
    align-items: center;
    padding: var(--menu-item-padding, 0em);
  }

  li.primary-menu-item:hover {
    background-color: var(--menu-item-hover-background-color, unset);
    color: var(--menu-item-hover-font-color, unset);
  }

  li.primary-menu-item.clicked {
    background-color: var(--menu-item-clicked-background-color, unset);
    color: var(--menu-item-clicked-font-color, unset);
  }

  .primary-menu > li:not(:last-child) {
    border-bottom: var(--menu-item-divider-size, 0px) solid var(--menu-item-divider-color, transparent);
  }

  li.primary-menu-item a {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  li.primary-menu-item .arrow-right {
    fill: currentColor;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: auto;
  }

  nav.secondary-nav {
    position: absolute;
    top: 0; /* position it 0 relative to primary nav which is absolute */
    left: 0;
    height: auto;
    min-height: 100%;
    width: 100%;
    z-index: -1;
    overflow: auto;
    opacity: 0;
    padding: var(--submenu-padding, 0em);

    background-color: var(--submenu-background-color, unset);
    background-image: var(--submenu-background-image, unset);
    background-size: var(--submenu-background-size, unset);
    background-repeat: var(--submenu-background-repeat, unset);
    background-position: var(--submenu-background-position, unset);
  }

  .back-link {
    padding: 0.81rem 0;
    margin-bottom: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: start;

    cursor: pointer;
  }

  .back-link .arrow-left {
    fill: currentColor;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }

  li.primary-menu-item.clicked nav.secondary-nav {
    opacity: 1;
    z-index: 10;
  }

  .secondary-menu {
    color: var(--submenu-font-color, unset);
    font-size: var(--submenu-font-size, unset);
    font-weight: var(--submenu-font-weight, unset);
    margin: 0;
    width: 100%;
  }

  li.secondary-menu-group:not(:last-child) {
    margin-bottom: 2rem;
  }

  li.group-title {
    margin-bottom: 0.25rem;
    color: var(--submenu-title-font-color, var(--theme-primary-accent-color, black));
    font-weight: var(--submenu-title-font-weight, inherit);
    font-size: var(--submenu-title-font-size, inherit);
    text-transform: uppercase;
    cursor: default;
    letter-spacing: 0.03125rem;
  }

  li.secondary-menu-item {
    padding: var(--submenu-item-padding, 0em);
  }

  li.secondary-menu-item:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  li.secondary-menu-item:hover {
    background-color: var(--submenu-item-hover-background-color, unset);
    color: var(--submenu-item-hover-font-color, unset);
  }

  .secondary-menu > li:not(:last-child) {
    border-bottom: var(--submenu-item-divider-size, 0px) solid var(--submenu-item-divider-color, transparent);
  }

  .card-list-area {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    margin-top: 2rem;
    padding: 2rem 0.5rem;
    text-align: center;

    display: none;
  }</style>
