<Boundary>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="modal-close-button" class:is-modal={isModal} on:click={handleCloseButtonClick} style={`--modal-close-button-offset-top: ${modalCloseOffsetPx || 0}px;`}>
    <svg viewBox="0 0 32 32">
      <use xlink:href={`#yc_icon_close`}></use>
    </svg>
  </div>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="cards-page-top-flex" on:click={handleTopClick} bind:this={topEl} style="
    {(minHeightWhileContentLoading && !$pageContentLoadedStore)? `min-height: ${minHeightWhileContentLoading};` : ''}
  ">
    {#if $editorModeEnabled}
      <div class="product-header">
        <img src="https://static.yinzcam.com/images/disco/product_header.png" alt="DISCOTech">
      </div>
      <div class="cards-page-control-bar">
        <CardsEditorControlBar />
      </div>
    {/if}
    <div class="cards-page-left-flex" class:editor-mode={$editorModeEnabled}>
      {#if $editorModeEnabled}
        <div class="cards-page-edit-bar">
          <CardsEditorEditBar />
        </div>
      {/if}
      {#if currentLayout}
        <div class="cards-page-content-area" class:hide-all-backgrounds={hideAllComponentBackgrounds} class:schematic-mode={!$previewModeEnabled} style="
          --cards-page-content-max-width: {contentMaxWidth}px;
          {(!($pageData.background && $pageData.background.attachment === 'FIXED' && Device.ios))? buildCssBackground(/*$pageData.background*/undefined, defaultBackground) : ''}
          {buildCssPadding($pageData.padding)}
          {buildCssFont($pageData.font)}
          {buildCssMargin($pageData.margin)}
          {themeStyle}
        " bind:this={contentAreaEl}>
          <div class="yinzcam-cards-page-ios-fixed-bg" style="{
            ($pageData.background && $pageData.background.attachment === 'FIXED' && Device.ios)?
            `${buildCssBackground({...$pageData.background, attachment: null})}
            width: 100%; height: 100%; position: fixed; top: 0; z-index: -7;
            ` :
            "display: none;"
          }"></div>
          <div class="cards-page-content-centering-div" use:resizeObserverAction={handleTopCenteringDivResize}></div>
          <CardsComponentWrapper {editorOptions} style="overflow-x: clip; ">
            <CardsRegion sequenceId="ROOT" region={currentLayout.region} on:domLoadedFirst on:domLoaded />
          </CardsComponentWrapper>
          <div class="cards-page-content-centering-div"></div>
        </div>
      {/if}
    </div>
  </div>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-page-top-flex {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .product-header {
    width: 100%;
  }

  .product-header > img {
    width: 100%;
  }

  .modal-close-button {
    position: absolute;
    top: calc(var(--modal-close-button-offset-top, 0) + 1.5rem);
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--theme-primary-accent-color, black);
    border-radius: 50%;
    background-color: var(--theme-primary-background-color, white);
    z-index: 5;
    cursor: pointer;
  }

  .modal-close-button:not(.is-modal) {
    display: none;
  }

  .modal-close-button > svg {
    width: 100%;
    height: 100%;
    vertical-align: unset;
  }

  .cards-page-left-flex {
    flex: 1 1 1px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* contain: content; */ /* starts a new containing block and stacking context for fixed position elements (e.g. sticky) */
  }

  .cards-page-left-flex.editor-mode {
    background-color: #ffffff;
  }

  .cards-page-edit-bar {
    flex: 0 0 305px;
    height: 100%;
    width: 305px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .cards-page-content-area {
    flex: 1 1 auto;
    max-width: var(--cards-page-content-max-width, none);
    background-color: transparent; /* TODO: MAKE THIS CONFIGURABLE */
    height: 100%;
    margin: auto;
    overflow-y: auto !important;
    font-family: var(--theme-font-family, unset);
    accent-color: var(--theme-primary-accent-color, unset);
  }

  .cards-page-content-area.hide-all-backgrounds :global(*) {
    background: none !important;
  }

  .cards-page-left-flex.editor-mode > .cards-page-content-area.schematic-mode :global(*:not(.cards-editor-component-overlay-controls)) {
    background: none !important;
  }

  /* For modal presentation, constrain the width and height to the content. */
  :global(div.page.modal-in) {
    height: 90vh;
    width: 80vw;
    /* overflow: scroll; */
  }

  :global(div.page.modal-in > .page-content) {
    overflow: visible;
  }

  :global(div.page.modal-in) .cards-page-left-flex {
    width: 100%;
  }

  :global(div.page.modal-in) .cards-page-content-area {
    display: flex;
    flex-direction: column;
  }

  :global(div.page.modal-in) .cards-page-content-area > :global(.wrapper) {
    flex: 1 0 auto;
  }

  :global(div.page.modal-in) .cards-page-content-area > .cards-page-content-centering-div {
    flex: 0 1 100%;
  }</style>

<script lang='ts'>var _a, _b, _c;
import ROOT from '../../../inversify.config';
import { getToken } from 'inversify-token';
import { YinzCamCardsServiceToken } from 'yinzcam-cards';
import { writable } from 'svelte/store';
import { windowMetrics as wm } from '../../../js/stores';
import CardsRegion from './CardsRegion.svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { createAnalyticsPageContext, buildCssBackground, buildCssFont, buildCssMargin, buildCssPadding, buildInlineStyleFromObject, initSequentialDisplay } from '../utilities';
import { setContext, onMount, onDestroy, tick, createEventDispatcher } from 'svelte';
import { CardsPageContextKey } from './context';
import CardsEditorControlBar from './CardsEditorControlBar.svelte';
import CardsEditorEditBar from './CardsEditorEditBar.svelte';
import { CardsEditorModeManager } from './CardsEditorModeManager';
import { ManualPassthrough } from 'yinzcam-rma';
import 'yinzcam-api';
import _, { uniqueId } from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_PAGE_SCHEMA } from "./CardsEditorComponentSchemata";
import './CardsEditorComponentInterfaces';
import { Device } from 'framework7';
import { f7 } from 'framework7-svelte';
import { CardsPageContentManagerToken } from './CardsPageContentManager';
import { JanusSignonManagerToken } from 'js/sso';
import { resolveUrl } from 'js/url';
import { JanusSEOManagerToken, JanusSEOPriority } from 'js/seo';
import { resizeObserverAction } from './actions';
import { JanusModeContextManagerToken } from 'js/mode';
import { JanusInternationalizationManagerToken } from 'js/i18n';
import { allViewsParams } from 'js/routes';
import { isEmbeddedPage } from 'js/utilities';
export let listName;
export let pathParams = [];
export let pageQuery = {};
export let pageData = undefined;
// TODO: Move these callbacks up one level into PageBuilder...
export let enableEditorModeOnMount = false;
export let isModal = false;
export let minHeightWhileContentLoading = null;
export let useSequentialDisplay = false;
export let onPageDataUpdate = undefined;
export let onFileUpload = undefined;
export let onDisableSave = undefined;
export let onEnableSave = undefined;
export let onDisableFullscreen = undefined;
export let onEnableFullscreen = undefined;
export let updater = undefined;
const pathParamsMap = [listName, ...pathParams].reduce((acc, cur, idx) => { acc[`path${idx}`] = cur; return acc; }, {});
const combinedParamsMap = Object.assign(Object.assign({}, pageQuery), pathParamsMap);
// TODO: Move this and onPageDataUpdate up one level into PageBuilder...
const editorModeManager = new CardsEditorModeManager(ROOT, onPageDataUpdate, onFileUpload, onDisableSave, onEnableSave, onDisableFullscreen, onEnableFullscreen);
const rootComponentLayerStore = editorModeManager.getRootComponentLayerStore();
const defaultParamsComp = new ManualPassthrough({ name: `CardsPage_${listName}_defaultParamsComp`, saveToLocalStorage: false }, {});
const pathParamsMappingComp = new ManualPassthrough({ name: `CardsPage_${listName}_pathParamsMappingComp`, saveToLocalStorage: false }, []);
const overrideParamsComp = new ManualPassthrough({ name: `CardsPage_${listName}_overrideParamsComp`, saveToLocalStorage: false }, {});
const contentManager = getToken(ROOT, CardsPageContentManagerToken);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
const seoManager = getToken(ROOT, JanusSEOManagerToken);
const seoConfigKey = uniqueId();
const modeManager = getToken(ROOT, JanusModeContextManagerToken);
const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
const allViewsParamsComp = new ManualPassthrough({ name: `CardsPage_${listName}_AllViewsParams`, saveToLocalStorage: false }, { params: {} });
const allViewsParamsStore = allViewsParamsComp.store;
const dispatch = createEventDispatcher();
const pageContentLoadedStore = writable(false);
const defaultBackground = {
    color: 'transparent',
    position: 'center',
    attachment: 'SCROLL'
};
let editorModeEnabled = editorModeManager.getEnabledStore();
let previewModeEnabled = editorModeManager.getPreviewStore();
let analytics = createAnalyticsPageContext();
let sentPageView = false;
let contentAreaEl;
let topEl;
let pageConfig;
let modalCloseOffsetPx;
let embeddedPage;
let topLevelPage;
let hideAllComponentBackgrounds = true;
setContext(CardsPageContextKey, {
    id: _.uniqueId('CardsPageContext'),
    name: listName,
    query: combinedParamsMap,
    stickyHeights: [],
    discoModeClass: (CONFIG.discoMode) ? 'disco-mode-enabled' : 'disco-mode-disabled',
    editorModeManager,
    defaultParamsComp,
    pathParamsMappingComp,
    overrideParamsComp,
    allViewsParamsComp,
    getAnalytics: () => analytics,
    // new tidy class in 2024
    contentManager,
    signonManager,
    modeManager,
    i18nManager,
    seoManager,
    pageContentLoadedStore,
    useSequentialDisplay,
});
// Analytics page view logic:
// - only send a page view once per load, even if the analytics info changes
// - this is to avoid inconsistencies in reported events
// - don't send the page view until the page data has loaded
$: if ($pageData && !sentPageView) {
    analytics = createAnalyticsPageContext($pageData.analytics, combinedParamsMap);
    analytics.sendPageView();
    sentPageView = true;
}
let sequentialDisplayStarted = false;
$: if ($pageData && contentAreaEl && !sequentialDisplayStarted) {
    sequentialDisplayStarted = true;
    tick().then(() => setTimeout(() => initSequentialDisplay(contentAreaEl, $pageData, topLevelPage, (tok) => {
        if (tok.sectionsDisplayed === 1) {
            hideAllComponentBackgrounds = false;
            dispatch('firstContentLoaded');
        }
    }).then(() => {
        hideAllComponentBackgrounds = false;
        dispatch('contentLoaded');
        pageContentLoadedStore.set(true);
    }), 100));
}
$: defaultParamsComp.setValue({ params: ($pageData === null || $pageData === void 0 ? void 0 : $pageData.defaultParameters) || {} });
$: pathParamsMappingComp.setValue(($pageData === null || $pageData === void 0 ? void 0 : $pageData.pathParameterMapping) || []);
$: overrideParamsComp.setValue({ params: ($editorModeEnabled) ? ($pageData === null || $pageData === void 0 ? void 0 : $pageData.editorParameters) || {} : {} });
let layouts = [];
$: if ($pageData && !$pageData.is404 && ((_a = $pageData.layouts) === null || _a === void 0 ? void 0 : _a.length) > 0 && (!layouts || layouts.length === 0)) {
    // console.log('SET NEW PAGE DATA');
    try {
        const pageDataLayouts = $pageData.layouts;
        layouts = ((pageDataLayouts === null || pageDataLayouts === void 0 ? void 0 : pageDataLayouts.length) > 0) ? pageDataLayouts.sort((a, b) => a.breakpoint - b.breakpoint) : [];
        if (!CONFIG.pageMultipleLayoutsAllowed && (pageDataLayouts === null || pageDataLayouts === void 0 ? void 0 : pageDataLayouts.length) > 1) {
            pageDataLayouts.splice(0, pageDataLayouts.length - 1);
            pageDataLayouts[0].breakpoint = 0;
            if (updater) {
                updater($pageData);
            }
            if (editorModeManager) {
                editorModeManager.notifyPageDataUpdate();
            }
        }
    }
    finally { }
}
else if ($pageData === null || $pageData === void 0 ? void 0 : $pageData.is404) {
    // redirect to 404
    // console.log('PAGE IS 404!!!', $pageData);
    resolveUrl("404").func();
}
let currentLayout = null;
$: if (layouts && $wm) {
    // if multiple layouts NOT allowed, take the last one (largest breakpoint)
    // if multiple layouts ARE allowed, find the right one
    let newLayout = layouts[layouts.length - 1];
    if (CONFIG.pageMultipleLayoutsAllowed) {
        for (let layout of layouts) {
            if ($wm.width >= layout.breakpoint) {
                newLayout = layout;
            }
            else {
                break;
            }
        }
    }
    if ((newLayout === null || newLayout === void 0 ? void 0 : newLayout.id) !== (currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.id)) {
        // console.log('SET NEW LAYOUT');
        currentLayout = newLayout;
    }
}
$: contentMaxWidth = ($wm === null || $wm === void 0 ? void 0 : $wm.width) || 'none';
$: pageThemeStore = contentManager.getThemeConfigurationStore(($pageData === null || $pageData === void 0 ? void 0 : $pageData.theme) || 'default');
//$: console.log('PAGE THEME', $pageThemeStore);
$: themeStyle = buildInlineStyleFromObject($pageThemeStore, 'theme');
let editorOptions;
//$: console.log('onPageDataUpdate', onPageDataUpdate);
$: editorOptions = {
    parentSequenceId: "",
    sequenceId: "ROOT",
    componentId: $pageData === null || $pageData === void 0 ? void 0 : $pageData.id,
    component: $pageData,
    componentTypeName: 'Page',
    childTypeNames: ['Region'],
    primaryColor: '#202020',
    reverseTextColor: 'white',
    unselectedBackgroundColor: '#e6e6e6',
    unselectedTextColor: '#000000',
    selectedBackgroundColor: 'rgb(0, 122, 255)',
    selectedTextColor: '#FFFFFF',
    childrenKey: 'layouts',
    forceWidth: '350px',
    configSpec: CARDS_PAGE_SCHEMA,
    allowChildren: false,
    allowDeletion: false,
    allowDuplication: false,
    allowMovement: false,
    updater: (page) => {
        if (updater) {
            updater(page);
        }
        editorModeManager.notifyPageDataUpdate();
    }
};
$: if (embeddedPage)
    editorModeManager.setEmbeddedPage();
/*
function enableEditorMode() {
  //console.log(`${listName}: enable editor mode`);
  editorModeManager.setEnabled(true);
}

function disableEditorMode() {
  //console.log(`${listName}: disable editor mode`);
  editorModeManager.setEnabled(false);
}
*/
function handleTopClick(e) {
    if (!contentAreaEl) {
        return;
    }
    const target = e.target;
    const isModal = target.closest('div.page.modal-in');
    const inContentArea = contentAreaEl.contains(target);
    const isContentArea = contentAreaEl === target;
    const isCenteringDiv = target.classList.contains('cards-page-content-centering-div');
    if (isModal && (!inContentArea || isContentArea || isCenteringDiv)) {
        //console.log('TOP CLICK DISMISS');
        f7.view.main.router.back();
    }
}
function handleCloseButtonClick(e) {
    const previousUrl = sessionStorage.getItem('previousUrl');
    const didNavigateToListenLive = sessionStorage.getItem('navigatedToListenLive');
    // f7.view.main.router.back();
    // console.log("Close clicked modal", {previousUrl, didNavigateToListenLive});
    if (previousUrl && didNavigateToListenLive === "true") {
        f7.view.main.router.navigate(previousUrl, { reloadCurrent: true });
    }
    else {
        f7.view.main.router.back();
    }
}
function handleTopCenteringDivResize(e) {
    if (isModal) {
        modalCloseOffsetPx = e.target.clientHeight;
    }
}
// Update SEO
$: if (topEl && !embeddedPage) {
    const pageTitle = ($pageData === null || $pageData === void 0 ? void 0 : $pageData.titleText) || ($pageData === null || $pageData === void 0 ? void 0 : $pageData.title) || (pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.title) || '';
    seoManager.registerSEOConfiguration({
        key: seoConfigKey,
        isPopup: !!topEl.closest('div.page.modal-in'),
        priority: JanusSEOPriority.Page,
        title: (pageTitle) ? pageTitle : CONFIG.defaultPageTitle,
        //title: CONFIG.defaultPageTitle + ((pageTitle)? ' - ' + pageTitle : ''),
        description: ((_b = pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.pageData) === null || _b === void 0 ? void 0 : _b.description) || ($pageData === null || $pageData === void 0 ? void 0 : $pageData.descriptionText) || CONFIG.defaultPageDescription,
        keywords: ((_c = pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.pageData) === null || _c === void 0 ? void 0 : _c.keywords) || ($pageData === null || $pageData === void 0 ? void 0 : $pageData.keywords) || CONFIG.defaultPageKeywords,
    });
}
let languageUnsubscribe = null;
let pageDataUnsub = null;
onMount(async () => {
    var _a;
    // console.log("PAGE MOUNTED");
    dispatch('pageMounted');
    if (enableEditorModeOnMount) {
        editorModeManager.setEnabled(true);
    }
    embeddedPage = isEmbeddedPage(topEl);
    topLevelPage = !_.isNil(embeddedPage) && !embeddedPage;
    if (!pageData) {
        //console.log('LOAD DEFAULT PAGE DATA');
        pageConfig = await contentManager.getPageConfiguration(listName);
        /*
        pageConfig = await new Promise((resolve, reject) => {
          pageDataUnsub = contentManager.getPageConfigurationStore(listName).subscribe((value) => {
            //console.log('PAGE CONFIG UPDATE', value);
            if (value?._success) {
              resolve(value);
            }
          });
        }) as any; // TODO: CREATE TYPE!
        */
        // console.log('GOT PAGE CONFIG', pageConfig);
        // TODO: Determine if a page for the requested language exists and if so load that instead of the default one.
        const language = CONFIG.defaultLanguage;
        if (!(pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.behavior)) {
            pageData = getToken(ROOT, YinzCamCardsServiceToken).getCardsPage(listName, language).store;
            // console.log('AttemptLoadPageData1', listName, language, $pageData);
        }
        else {
            switch (pageConfig.behavior) {
                case "Redirect":
                    const resolvedUrl = resolveUrl(pageConfig.linkUrl);
                    resolvedUrl.func();
                    if (resolvedUrl.external) {
                        f7.view.main.router.back();
                    }
                    break;
                case "Display Page Layout":
                    // console.log('AttemptLoadPageData2', listName, language, $pageData);
                    pageData = getToken(ROOT, YinzCamCardsServiceToken).getCardsPage(((_a = pageConfig.pageLayout) === null || _a === void 0 ? void 0 : _a.slug) || listName, language).store;
                    break;
            }
        }
    }
    // listen for language changes and switch languages if top level page
    if (topLevelPage) {
        /*
        let currentLang = modeManager.getCurrentLanguage();
        languageUnsubscribe = modeManager.getCurrentLanguageStore().subscribe((newLang) => {
          console.log('LANGUAGE CHANGE', currentLang, newLang);
          if (currentLang !== newLang) {
            currentLang = newLang;
            // NOT SURE WHAT THIS WAS FOR????
            //console.log('???????????????', resolveUrl('.', undefined, { language: newLang }));
            if ($pageContentLoadedStore) {
              const rurl = resolveUrl('.', undefined, { language: newLang });
              const nurl = new URL(rurl.href, location.href).toString();
              //console.log('WTF??????????', nurl);
              //setTimeout(() => window.open(nurl, '_self'), 0);
            }
          }
        });
        */
        allViewsParamsStore.subscribe((params) => {
            try {
                allViewsParams.set(params === null || params === void 0 ? void 0 : params.params);
                const queryParams = new URLSearchParams(window.location.search);
                let madeReplacement = false;
                for (const key in params.params) {
                    const val = params.params[key];
                    if (val && queryParams.get(key)) {
                        queryParams.set(key, val);
                        madeReplacement = true;
                    }
                }
                if (madeReplacement) {
                    console.log('MAKING QS REPLACEMENT!', queryParams);
                    history.replaceState(null, null, new URL('?' + queryParams.toString(), window.location.href));
                }
            }
            catch (e) {
                console.log('caught exception setting all views params', e);
            }
        });
    }
});
onDestroy(() => {
    if (pageDataUnsub) {
        pageDataUnsub();
    }
    if (languageUnsubscribe) {
        languageUnsubscribe();
    }
    seoManager.unregisterSEOConfiguration(seoConfigKey);
});
</script>
