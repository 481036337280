<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import "js/url";
import { ImageParticle, ButtonParticle, TextParticle, } from "components/cards/particles/YinzCam";
import "svelte";
export let container;
export let bottomRowPadding;
export let btnSmallSponsor = undefined;
export let btnLargeSponsor = undefined;
export let btnXtraSmallSponsor = undefined;
export let imgDivider = undefined;
export let imgTeamLogo = undefined;
export let cardBackgroundColor = undefined;
export let txtUsefulLinks = undefined;
export let btnUsefulLinksArray = undefined;
export let txtFindUs = undefined;
export let btnFindUsArray = undefined;
export let txtFollowUs = undefined;
export let txtFollowUsArray = undefined;
export let btnClubSocialIcons = undefined;
export let btnMensSocialIcons = undefined;
export let btnWomensSocialIcons = undefined;
export let btnAppStoreIcons = undefined;
export let btnBoilerplateLinks = undefined;
const getSocialIconButtons = (string) => {
    if (string) {
        if (string.toLowerCase().includes("club"))
            return btnClubSocialIcons;
        if (string.toLowerCase().includes("men"))
            return btnMensSocialIcons;
        if (string.toLowerCase().includes("women"))
            return btnWomensSocialIcons;
        if (string.toLowerCase().includes("app"))
            return btnAppStoreIcons;
    }
    return [];
};
let atomWidthPx;
$: isMobile = atomWidthPx <= 650;
$: isTablet = atomWidthPx <= 1025 && atomWidthPx > 650;
$: imgMultiplier = isMobile ? "0.5" : isTablet ? "0.75" : "1.0";
$: imgTeamLogoMultiplier = isMobile ? "0.7" : isTablet ? "0.85" : "1.0";
$: txtMenusMultiplier = isMobile ? "1.5" : "1.0";
$: txtLinksMultiplier = isMobile ? "1.25" : "1.0";
$: imgSocialIconMultiplier = isMobile ? "1.4" : "1.0";
</script>

<BaseAtom
    _isotope="LeicesterFooterAtom"
    _cssVariablePrefix="lei-footer"
    {...container}
    {bottomRowPadding}
    {cardBackgroundColor}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile}
    >
        <div class="top-row">
            <div class="sponsor-buttons">
                {#if btnLargeSponsor?.[0]?.image?.source}
                    <div class="large-buttons-row">
                        {#each btnLargeSponsor || [] as largeButton}
                            <div class="large-button">
                                <ButtonParticle
                                    {...merge({}, largeButton, {
                                        image: {
                                            container: {
                                                width: `calc(${get(largeButton, "image.container.width")}*${imgMultiplier})`,
                                                height: `calc(${get(largeButton, "image.container.height")}*${imgMultiplier})`,
                                            },
                                        },
                                    })}
                                />
                            </div>
                        {/each}
                    </div>
                {/if}
                {#if btnSmallSponsor?.[0]?.image?.source}
                    <div class="small-buttons-row">
                        {#each btnSmallSponsor || [] as smallButton}
                            <div class="small-button">
                                <ButtonParticle
                                    {...merge({}, smallButton, {
                                        image: {
                                            container: {
                                                width: `calc(${get(smallButton, "image.container.width")}*${imgMultiplier})`,
                                                height: `calc(${get(smallButton, "image.container.height")}*${imgMultiplier})`,
                                            },
                                        },
                                    })}
                                />
                            </div>
                        {/each}
                    </div>
                {/if}
                {#if btnXtraSmallSponsor?.[0]?.image?.source}
                    <div class="xtra-small-buttons-row">
                        {#each btnXtraSmallSponsor || [] as xSmallButton}
                            <div class="xtra-small-button">
                                <ButtonParticle
                                    {...merge({}, xSmallButton, {
                                        image: {
                                            container: {
                                                width: `calc(${get(xSmallButton, "image.container.width")}*${imgMultiplier})`,
                                                height: `calc(${get(xSmallButton, "image.container.height")}*${imgMultiplier})`,
                                            },
                                        },
                                    })}
                                />
                            </div>
                        {/each}
                    </div>
                {/if}
            </div>
            <div class="divider-image">
                <ImageParticle {...imgDivider} _block={true} />
            </div>
        </div>
        <div class="bottom-row">
            <div class="lei-crest">
                <ImageParticle {...imgTeamLogo} />
            </div>
            <div class="links-address-social">
                <div class="useful-links-col">
                    <div class="submenu-heading">
                        <TextParticle
                            {...txtUsefulLinks}
                            size={`calc(${get(txtUsefulLinks, "size")}*${txtMenusMultiplier})`}
                        />
                    </div>
                    <div class="submenu-content">
                        {#each btnUsefulLinksArray || [] as link}
                            <ButtonParticle
                                {...merge({}, link, {
                                    text: {
                                        size: `calc(${get(link, "text.size")}*${txtMenusMultiplier})`,
                                    },
                                })}
                            />
                        {/each}
                    </div>
                </div>
                <div class="find-us-col">
                    <div class="submenu-heading">
                        <TextParticle
                            {...txtFindUs}
                            size={`calc(${get(txtFindUs, "size")}*${txtMenusMultiplier})`}
                        />
                    </div>
                    <div class="submenu-content">
                        {#each btnFindUsArray || [] as find, idx}
                            <ButtonParticle
                                {...merge({}, find, {
                                    text: {
                                        size: `calc(${get(find, "text.size")}*${txtMenusMultiplier})`,
                                    },
                                })}
                            />
                        {/each}
                    </div>
                </div>
                <div class="follow-us-col">
                    <div class="submenu-heading">
                        <TextParticle
                            {...txtFollowUs}
                            size={`calc(${get(txtFollowUs, "size")}*${txtMenusMultiplier})`}
                        />
                    </div>
                    <div class="submenu-content">
                        {#each txtFollowUsArray || [] as follow}
                            <div class="social-icons-box">
                                <TextParticle
                                    {...merge({}, follow, {
                                        size: `calc(${get(follow, "size")}*${txtMenusMultiplier})`,
                                    })}
                                />
                                {#if follow?.text && (btnAppStoreIcons || btnClubSocialIcons || btnFindUsArray || btnFollowUsArray)}
                                    <div class="social-icons-row">
                                        {#each getSocialIconButtons(follow?.text) || [] as icon}
                                            <ButtonParticle
                                                {...merge({}, icon, {
                                                    container: {
                                                        width: `calc(${get(icon, "container.width")}*${imgSocialIconMultiplier})`,
                                                        height: `calc(${get(icon, "container.height")}*${imgSocialIconMultiplier})`,
                                                    },
                                                })}
                                            />
                                        {/each}
                                    </div>
                                {/if}
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="divider-line"></div>
            <div class="boilerplate-links">
                {#each btnBoilerplateLinks || [] as link, idx}
                    <!-- <div class="boiler-link"> -->
                    <ButtonParticle
                        {...merge({}, link, {
                            text: {
                                size: `calc(${get(link, "text.size")}*${txtLinksMultiplier})`,
                            },
                        })}
                    />
                    {#if idx !== btnBoilerplateLinks?.length - 1 && !isMobile}
                        <div class="pipe-shape"></div>
                    {/if}
                    <!-- </div> -->
                {/each}
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .main-container .top-row {
            width: 100%;
            padding-top: 3rem;
        }

    .main-container .top-row .sponsor-buttons {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 3rem;
            }

    .main-container .top-row .sponsor-buttons .large-buttons-row,
                .main-container .top-row .sponsor-buttons .small-buttons-row,
                .main-container .top-row .sponsor-buttons .xtra-small-buttons-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 4rem;
                }

    .main-container .top-row .sponsor-buttons .small-buttons-row {
                    gap: 4rem;
                }

    .main-container .top-row .sponsor-buttons .large-button, .main-container .top-row .sponsor-buttons .small-button, .main-container .top-row .sponsor-buttons .xtra-small-button{
                    max-width: 10rem;
                }

    .main-container .top-row .divider-image {
                width: 100%;
                margin-bottom: -0.10rem;
            }

    .main-container .bottom-row {
            width: 100%;
            padding: 0 10vw; /* var(--lei-footer-bottom-row-padding, unset);*/
            padding-bottom: 3rem;
            background-color: var(--lei-footer-card-background-color, unset);
        }

    .main-container .bottom-row .lei-crest {
                align-self: flex-start;
            }

    .main-container .bottom-row .links-address-social {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: 3rem 0;
            }

    .main-container .bottom-row .links-address-social .submenu-content {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 1rem;
                }

    .main-container .bottom-row .links-address-social .submenu-content .social-icons-box {
                        display: grid;
                        grid-template-columns: 1.25fr 3fr;
                        align-items: center;
                        grid-gap: 0.5rem;
                        gap: 0.5rem;
                        width: 100%;
                    }

    .main-container .bottom-row .links-address-social .submenu-content .social-icons-row {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                    }

    .main-container .bottom-row .divider-line {
                width: 100%;
                height: 1px;
                border-top: 1px solid #fff;
                margin: 0 0 3rem 0;
                opacity: 0.5;
            }

    .main-container .bottom-row .boilerplate-links {
                /* width: 100%;
                display: flex;
                align-items: normal;
                justify-content: normal;
                gap: 1rem;
                flex-wrap: wrap;
                overflow: revert; */

                display: flex;
                flex-direction: row;
                justify-content: normal;
                align-items: normal;
                gap: 12px;
                width: 100%;
                height: auto;
                overflow: revert;
                flex-wrap: wrap;
            }

    .main-container .bottom-row .boilerplate-links .pipe-shape {
                    height: 16px;
                    width: 2px;
                    border-right: 1px solid #fff;
                }

    .main-container .bottom-row .boilerplate-links .pipe-shape {
                    height: 12px;
                    width: 1px;
                    border-right: 1px solid #fff;
                    align-self: center;
                }

    .main-container .bottom-row .boilerplate-links .boiler-link {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

    .main-container .bottom-row .boilerplate-links .boiler-link > * > :global(*) {
                        justify-content: unset;
                    }

    .main-container.layout-tablet-width .top-row .sponsor-buttons {
                gap: 3rem;
            }

    .main-container.layout-tablet-width .top-row .sponsor-buttons .large-buttons-row,
                .main-container.layout-tablet-width .top-row .sponsor-buttons .small-buttons-row,
                .main-container.layout-tablet-width .top-row .sponsor-buttons .xtra-small-buttons-row {
                    gap: 3rem;
                }

    .main-container.layout-tablet-width .top-row .sponsor-buttons .small-buttons-row {
                    gap: 3rem;
                }

    .main-container.layout-tablet-width .bottom-row {
            padding: 0 3vw; /* var(--lei-footer-bottom-row-padding, unset);*/
            padding-bottom: 3rem;
        }

    .main-container.layout-mobile-width .top-row .sponsor-buttons {
                gap: 1rem;
            }

    .main-container.layout-mobile-width .top-row .sponsor-buttons .large-buttons-row,
                .main-container.layout-mobile-width .top-row .sponsor-buttons .small-buttons-row,
                .main-container.layout-mobile-width .top-row .sponsor-buttons .xtra-small-buttons-row {
                    gap: 1.5rem;
                }

    .main-container.layout-mobile-width .top-row .sponsor-buttons .large-buttons-row{
                    padding-bottom: 1rem;
                }

    .main-container.layout-mobile-width .top-row .sponsor-buttons .small-buttons-row {
                    gap: 1rem;
                    padding: 0 1rem;
                }

    .main-container.layout-mobile-width .top-row .sponsor-buttons .xtra-small-buttons-row{
                    padding: 0 1rem;
                }

    .main-container.layout-mobile-width .bottom-row {
            padding: 0 5vw; /* var(--lei-footer-bottom-row-padding, unset);*/
            padding-bottom: 3rem;
        }

    .main-container.layout-mobile-width .bottom-row .links-address-social {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 5rem;
                gap: 5rem;
                padding: 6rem 0;
            }

    .main-container.layout-mobile-width .bottom-row .boilerplate-links {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: flex-start;
            }

    .main-container.layout-mobile-width .bottom-row .boilerplate-links .boiler-link > :global(*) {
                    justify-content: unset;
                }</style>
