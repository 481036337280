import { OpenIDProvider } from '@salte-auth/salte-auth';
import { injectable, Container, LazyServiceIdentifer, inject, unmanaged } from "inversify";
import { injectToken, getToken, Token, TokenContainerModule } from "inversify-token";
import { YinzCamConfigServer, YinzCamConfigServerToken } from "yinzcam-config";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { JanusModeContextManager, JanusModeContextManagerToken } from "./mode";
import { get, Readable, writable, Writable } from "svelte/store";
import { YinzCamSignonServer, YinzCamSignonServerToken } from 'yinzcam-signon';
import { YinzCamDSPServerToken, YinzCamDSPServer } from 'yinzcam-signon';
import { AbstractReactiveMicrocomponent, ControlBase, DeadlineTimer, ManualPassthrough, OutputMapper, ReactiveMicrocomponent, ReactiveMicrocomponentConfig, SimpleComponent, SimpleSink } from 'yinzcam-rma';
import { YinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameterComponentToken, YinzCamAPIRequestParameters, YinzCamAPIResponse } from 'yinzcam-api';
import { ycSSOAuthFinished, ycTicket } from './ycapp';
import { SalteAuth } from '@salte-auth/salte-auth';
import { Tab } from '@salte-auth/tab';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
//import { YinzCamDropsServer, YinzCamDropsServerToken } from 'yinzcam-drops';
// TODO: Move this into a PGA server
import { YinzCamAppServer, YinzCamAppServerToken } from 'yinzcam-appserver';
import type { YinzCamSSOConfig, YinzCamSSO as YinzCamSSOSDK, YinzCamSSOTicket } from 'lib/yinzcam-sso-sdk/lib/YinzCamSSO';
import type { EnvironmentIdentifier } from 'lib/yinzcam-sso-sdk/lib/Utilities';
import { resolveUrl } from './url';

export const JanusSignonManagerToken = new Token<JanusSignonManager>(Symbol.for("JanusSignonManager"));

const JanusSignonStateManagerToken = new Token<JanusSignonStateManager>(Symbol.for("JanusSignonStateManager"));

const OAUTH_USE_SDK_PARAM = 'useSdk';
const OAUTH_LOGIN_PROVIDER_PARAM = 'loginProvider';
const OAUTH_FINAL_URI_PARAM = 'finalUri';

const OAUTH_KORE_BRANDED_LOGIN = 'KOREBrandedLogin';

export let JanusSignonManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusSignonStateManagerToken).to(JanusSignonStateManager).inSingletonScope();
    bindToken(JanusSignonManagerToken).to(JanusSignonManager).inSingletonScope();
    bindToken(YinzCamAPIRequestParameterComponentToken).to(JanusSignonManagerParameterComponent).inSingletonScope();
  }));
});

export interface SSOResult {
  success: boolean;
  data?: any;
  errorCode?: string;
  errorMessage?: { 
    title: string; 
    description: string
  };
}

export interface SignonStatus {
  loggedIn: boolean;
  ticket?: string;
  expiration?: number;
  validSpan?: number;
  yinzId?: string;
  accountCreated?: boolean;
  dropsTicket?: string;
  serviceTicket?: string;
};

const INTERNAL_ERROR: SSOResult = {
  success: false,
  errorCode: 'INTERNAL_ERROR',
  errorMessage: {
    title: 'Internal Error',
    description: 'An internal error occurred.'
  }
};

// this is stored outside of the manager class to avoid circular dependencies with the parameter component
@injectable()
class JanusSignonStateManager {
  private readonly statusComponent: ManualPassthrough<SignonStatus>;
  //private readonly expirationTimeComponent: ReactiveMicrocomponent<number>;
  //private readonly expirationTimerComponent: ReactiveMicrocomponent<number>;
  //private readonly expirationHandlerComponent: ReactiveMicrocomponent<void>;

  public constructor() {
    let ssInit: SignonStatus = null;
    try {
      ssInit = JSON.parse(window.localStorage.getItem('JanusSignonStateManager_Status'));
    } catch {
      console.log("JanusSignonStateManager unable to load previous state, setting logged out");
      ssInit = null;
    }
    if (!ssInit) {
      ssInit = { loggedIn: false };
    }
    this.statusComponent = new ManualPassthrough({ name: 'JanusSignonStatusComponent', saveToLocalStorage: !CONFIG.ssoDiscardLoginStateAtEndOfSession }, ssInit);
    /*
    this.expirationTimeComponent = new OutputMapper(
      { name: 'JanusSignonStatusExpirationTimeComponent', saveToLocalStorage: false }, this.statusComponent,
      ($status) => {
        if (!$status?.loggedIn || !$status.expiration) {
          return 0x7FFFFFFF;
        }
        // give 60 second buffer before real end of ticket lifetime
        return $status.expiration - 60*1000;
      });
    this.expirationTimerComponent = new DeadlineTimer('JanusSignonStatusExpirationTimerComponent', this.expirationTimeComponent);
    this.expirationHandlerComponent = new SimpleSink(
      { name: 'JanusSignonStatusExpirationHandlerComponent', saveToLocalStorage: false },
      async ($timer: number) => {
        if (!$timer) {
          return;
        }
        //console.log('JanusSignonStatusExpirationHandlerComponent: timer fired, logging out');
        //this.statusComponent.setValue({ loggedIn: false });
      }, this.expirationTimerComponent);    
      */
  }

  public getStatusComponent(): ReactiveMicrocomponent<SignonStatus> {
    return this.statusComponent;
  }

  public getStatus(): SignonStatus {
    return this.statusComponent.getValue();
  }

  private setStatusInternal(status: SignonStatus) {
    window.localStorage.setItem('JanusSignonStateManager_Status', JSON.stringify(status));
    this.statusComponent.setValue(status);
  }

  public updateTicketStatus(status: SignonStatus) {
    if (!status?.loggedIn || !status?.ticket || !status?.expiration) {
      console.log('JanusSignonStateManager.updateTicketStatus: not logged in or invalid ticket, setting logged out');
      this.setStatusInternal({ loggedIn: false });
      return;
    }
    // for testing expiration logout
    //status.expiration = Date.now() + 90000;
    console.log('JanusSignonStateManager (web-platform): status updated', status);
    this.setStatusInternal(status);
  }
}

@injectable()
export class JanusSignonManager {
  private readonly appId: string;
  private readonly sdk: YinzCamSSOSDK;

  public constructor (
    @injectToken(JanusModeContextManagerToken) private readonly modeManager: JanusModeContextManager,
    @injectToken(YinzCamSignonServerToken) private readonly signonServer: YinzCamSignonServer,
    @injectToken(YinzCamDSPServerToken) private readonly dspServer: YinzCamDSPServer,
    @injectToken(YinzCamConfigServerToken) private readonly configServer: YinzCamConfigServer,
    @injectToken(JanusSignonStateManagerToken) private readonly stateManager: JanusSignonStateManager,
    //@injectToken(YinzCamDropsServerToken) private readonly dropServer: YinzCamDropsServer,
    @injectToken(YinzCamAppServerToken) private readonly appServer: YinzCamAppServer) {
    this.appId = `${CONFIG.league}_${CONFIG.tricode}`.toUpperCase();

    // check for redirects from external login pages
    if (CONFIG.ssoOAuthRedirectPath === window.location.pathname) {
      // Assume this is a page running within a popup created by this library.
      const searchParams = new URLSearchParams(window.location.search);
      const useSdk = searchParams.get(OAUTH_USE_SDK_PARAM);
      if (useSdk === 'false') {
        const loginProvider = searchParams.get(OAUTH_LOGIN_PROVIDER_PARAM);
        const finalUri = searchParams.get(OAUTH_FINAL_URI_PARAM);
        if (loginProvider && finalUri) {
          const redirectToFinalUri = () => {
            // allow the dust to settle...
            setTimeout(() => {
              console.log('JanusSignonManager redirect to final URI', finalUri);
              window.location.href = finalUri;  
            }, 0);
          };
          switch (loginProvider) {
            case OAUTH_KORE_BRANDED_LOGIN:
              const tokenPayload = searchParams.get('tokenpayload');
              this.loginWithSportsAllianceTokenPayload(tokenPayload).then(redirectToFinalUri);
              break;
            default:
              console.error('JanusSignonManager unknown login provider', loginProvider);
              redirectToFinalUri();
              break;
          }
        } else {
          window.location.href = '/';        
        }
      }
    }

    // Configure the YinzCam SSO UI SDK.
    this.sdk = new YinzCamSSO({
      appId: this.appId,
      env: (CONFIG.ssoEnvironment || CONFIG.environment) as EnvironmentIdentifier,
      oauthRedirectPath: CONFIG.ssoOAuthRedirectPath,
      setTicketHandler: (ticket, accountCreated) => {
        console.log('JanusSignonManager: set ticket from SSO UI SDK', ticket);
        if (!ticket || !ticket.encoded) {
          return;
        }
        console.log('JanusSignonManager: updating ticket status from SSO UI SDK');
        this.stateManager.updateTicketStatus({
          loggedIn: true,
          ticket: ticket.encoded,
          expiration: ticket.expireTime,
          validSpan: ticket.validSpan,
          //dropsTicket: await this.getDropTicket(ticket.encoded),
          //serviceTicket: await this.getServiceTicket(ticket.encoded),
          yinzId: ticket.yinzId,
        });
      },
      getTicketHandler: () => {
        const status = this.getStatus();
        console.log('get ticket handler', status);
        if (!status?.loggedIn) {
          return null;
        }
        console.log('get ticket handler', status);
        return {
          encoded: status.ticket,
          expireTime: status.expiration,
          validSpan: status.validSpan,
          yinzId: status.yinzId
        };
      }
    } as YinzCamSSOConfig);

    // Configure receiving the ticket from a container mobile app.
    (ycTicket as Readable<{ticket: string, validSpan: number}>).subscribe((ti) => {
      if (!ti || !ti.ticket || !ti.validSpan) {
        return;
      }
      console.log('JanusSignonManager: updating ticket status from ycapp');
      setTimeout(async () => {
        this.stateManager.updateTicketStatus({
          loggedIn: true,
          ticket: ti.ticket,
          expiration: Date.now() + ti.validSpan,
          validSpan: ti.validSpan,
          //dropsTicket: await this.getDropTicket(ti.ticket),
          //serviceTicket: await this.getServiceTicket(ti.ticket)
        });
      }, 0);
    });
  }

  public getSdk(): YinzCamSSOSDK {
    return this.sdk;
  }

  public requireAuth() {
    if (CONFIG.ssoOAuthLoginProvider) {
      switch (CONFIG.ssoOAuthLoginProvider) {
        case OAUTH_KORE_BRANDED_LOGIN:
          this.redirectToKOREBrandedLogin();
          break;
        default:
          console.error('JanusSignonManager unknown login provider', CONFIG.ssoOAuthLoginProvider);
          break;
      }
    } else {
      this.getSdk().requireAuth();
    }
  }

  public showCompleteProfilePage() {
    if (CONFIG.ssoCustomProfilePage) {
      resolveUrl(CONFIG.ssoCustomProfilePage).func();
    } else {
      this.getSdk().showCompleteProfilePage();
    }
  }

  private async updateTicketStatus(status: SignonStatus) {
    this.stateManager.updateTicketStatus({
      ...status,
      //dropsTicket: await this.getDropTicket(status.ticket),
      //serviceTicket: await this.getServiceTicket(status.ticket)
    });
  }

  //Alumni chat
  private async getServiceTicket(ssoTicket: string) {
    const res = await this.appServer.getTicket(ssoTicket);
    console.log('getServiceTicket: ', res);
    return (<any>res?.data)?.Ticket;
  }

  // TODO: REMOVE THIS FROM HERE!!
  /*
  private async getDropTicket(ssoTicket: string) {
    let data = {"UMS":{"Ticket":`${ssoTicket}`}};
    let dropTicket: any = await this.dropServer.getDropTicket(data).then(res => res?.data);
    console.log('dropTicket: ', dropTicket);
    //this.dropServer.setDropTicket(dropTicket);
    return dropTicket?.Ticket;
  }
  */

  public getStatusComponent(): ReactiveMicrocomponent<SignonStatus> {
    return this.stateManager.getStatusComponent();
  }

  public getStatus(): SignonStatus {
    return this.stateManager.getStatus();
  }

  public logout(): SignonStatus {
    sessionStorage.removeItem('previousUrl');
    sessionStorage.removeItem('navigatedToListenLive');
    this.updateTicketStatus({ loggedIn: false });
    return this.stateManager.getStatus();
  }

  /*
  <TicketResponse>
	<Ticket>+n2I1dH3Q22T0Zb85VSbZwAAAXnjpnikhFEO+itsfbW4U9pa/X4VQcgpxS5RkRL7grCGAr61s1DIyziZP2o0xjH92dVZK0atCB/Wjy2mh20C/zb0OkOTq6JpOZBq/oXSXeM0UFNDS+UPdJZIdLijDpodtRFGk/3a</Ticket>
	<IssueTime>1623022008484</IssueTime>
	<ExpireTime>1626622008484</ExpireTime>
	<ValidSpan>3600000000</ValidSpan>
	<YinzId>b8b1eff1-f91b-4872-ad43-e0c98b3d131a</YinzId>
	<AccountCreated>true</AccountCreated>
</TicketResponse>
*/

  /*
  <RestErrorResponse>
	<ErrorCode>DSP_SERVER_ERROR</ErrorCode>
	<UserMessage>
		<Title>Registration Error</Title>
		<Content>Looks like you've already signed up with this email.  Try logging in with your email and password.</Content>
	</UserMessage>
</RestErrorResponse>
*/
  private async handleTicketGrantingAPIResponse(rsp: YinzCamAPIResponse): Promise<SSOResult> {
    if (!rsp?.data) {
      return INTERNAL_ERROR;
    }
    let data = rsp.data as any;
    if (data.Ticket && data.ExpireTime) {
      this.updateTicketStatus({
        loggedIn: true,
        ticket: data.Ticket,
        expiration: parseInt(data.ExpireTime),
        validSpan: parseInt(data.ValidSpan),
        yinzId: data.YinzId,
        accountCreated: data.AccountCreated === 'true'
      });
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ success: true });
        }, 0);
      })
    } else if (data.ErrorCode) {
      // TODO: make this a common utility function
      return {
        success: false,
        errorCode: data.ErrorCode,
        errorMessage: {
          title: data.UserMessage?.Title || 'Error',
          description: data.UserMessage?.Content || 'An error occurred.'
        }
      }
    } else {
      return INTERNAL_ERROR;
    }
  }

  public async registerWithDSPUserPassword(user: string, password: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postRegister({
      Credentials: {
        DSPAccount: {
          AppId: this.appId,
          ClientId: CONFIG.dspClientId,
          DeviceId: await this.getDSPGuestId(),
          Username: user,
          Password: password
        }
      },
      Profile: {
        Entry: [{
          Key: 'locale',
          Value: navigator.language
        }]
      }
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  private createMapFromProfileResponse(data: any): { [key: string]: string } {
    if (!data?.Entry) {
      return {};
    }
    let entries = data.Entry as { Key: string, Value: string}[];
    let dict = Object.fromEntries(entries.map(e => [e.Key, e.Value])); 
    return dict;
  }

  public async getProfileSegment(segment: string): Promise<SSOResult> {
    let rsp = await this.signonServer.getProfile2UserSegment(segment);
    let data = rsp?.data as any;
    return { success: !rsp.isStatusNot2xx, data: this.createMapFromProfileResponse(data) };
  }

  public async getProfileSegmentScoped(segment: string, scope?: string): Promise<SSOResult> {
    if (!scope) {
      scope = `${CONFIG.league}_${CONFIG.tricode}`.toUpperCase();
    }
    return this.getProfileSegment(`${segment}-${scope}`);
  }

  public getProfileSegmentComponent(segment: string): ReactiveMicrocomponent<{ [key: string]: string }> {
    let ic = this.signonServer.getProfile2UserSegmentComponent(segment);
    let name = `sso:getProfileSegmentComponent:${segment}`;
    return new OutputMapper({ name, saveToLocalStorage: false }, ic, (rsp) => {
      return this.createMapFromProfileResponse(rsp?.data);
    });
  }

  public getDefaultProfileSegmentComponent(): ReactiveMicrocomponent<{ [key: string]: string }> {
    return this.getProfileSegmentComponent(CONFIG.defaultSSOProfileSegment);
  }

  public getDefaultProfileSegmentStore(): Readable<{ [key: string]: string }> {
    return this.getProfileSegmentStore(CONFIG.defaultSSOProfileSegment);
  }

  public getProfileSegmentStore(segment?: string): Readable<{ [key: string]: string }> {
    return this.getProfileSegmentComponent(segment || CONFIG.defaultSSOProfileSegment).store;
  }

  public async updateProfileSegment(segment: string, profile: { [key: string]: string }): Promise<SSOResult> {
    let entries = Object.entries(profile).filter((e) => !['yinzid', 'segment'].includes(e[0])).map(e => ({ Key: e[0], Value: e[1] }));
    let data = { Entry: entries };
    let rsp = await this.signonServer.postProfile2UserSegment(segment, data);
    data = rsp?.data as any;
    if (!data.Entry) {
      return INTERNAL_ERROR;
    }
    return { success: true };
  }

  public updateDefaultProfileSegment(profile: { [key: string]: string }): Promise<SSOResult> {
    return this.updateProfileSegment(CONFIG.defaultSSOProfileSegment, profile);
  }

  public async getVideoToken(videoId: string): Promise<SSOResult> {
    let rsp = await this.signonServer.getVideoToken(videoId);
    let data = rsp?.data as any;
    console.log('GET VIDEO TOKEN DATA', data);
    return { success: !rsp.isStatusNot2xx, data: { token: data.VideoToken } };
  }

  public async updateUser(profile: { [key: string]: string }): Promise<SSOResult> {
    // let entries = Object.entries(profile).map(e => ({ Key: e[0], Value: e[1] }));
    // let data = { Entry: entries };
    let rsp = await this.signonServer.postUser(this.getStatus().ticket, profile);
    const data = rsp?.data as any;
    if (!data.Entry) {
      return INTERNAL_ERROR;
    }
    return { success: true };
  }

  public async loginWithYinzCamUserPassword(user: string, password: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postTicket({
      YinzCam: {
        AppId: this.appId,
        Identity: user,
        Passphrase: password
      },
      CreateNewAccount: false
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  public async loginWithLow6UserPassword(user: string, password: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postTicket({
      Low6: {
        AppId: this.appId,
        Username: user,
        Password: password
      }
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  public async registerWithLow6UserPassword(user: string, password: string, profile: { [key: string]: string }): Promise<SSOResult> {
    let rsp = await this.signonServer.postRegister( {
      Credentials: {
        Low6: {
          AppId: this.appId,
          Username: user,
          Password: password
        }
      },
      Profile: { Entry: Object.entries(profile).map(e => ({ Key: e[0], Value: e[1] })) }
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  public async resetWithLow6UserPassword(user: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postPasswordReset(this.getStatus().ticket, {
        Low6: {
          AppId: this.appId,
          Username: user,
        }
    });
    
    if (!rsp || rsp.isStatusNot2xx) {
      return INTERNAL_ERROR;
    }
    return { success: true };
  }

  public getDSPProfileSegment(): Promise<SSOResult> {
    return this.getProfileSegment('DSPACCOUNT');
  }

  public getDSPProfileSegmentComponent(): ReactiveMicrocomponent<{ [key: string]: string }> {
    return this.getProfileSegmentComponent('DSPACCOUNT');
  }

  public async checkDSPAccountVerification(): Promise<SSOResult> {
    let res = await this.getDSPProfileSegment();
    if (!res?.success) {
      return INTERNAL_ERROR;
    }
    return { success: res.data?.['verified'] === 'true' };
  }

  public updateDSPProfileSegment(profile: { [key: string]: string }): Promise<SSOResult> {
    return this.updateProfileSegment('DSPACCOUNT', profile);
  }

  public async uploadDSPProfileImage(image: Blob, name: string): Promise<SSOResult> {
    let formData = new FormData();
    formData.append('file', image, name);
    let rsp = await this.signonServer.postUploadImageSegment('DSPAccount', formData);
    //console.log('uploadDSPProfileImage', rsp);
    return { success: true, data: rsp.data };
  }

  public async loginWithDSPUserPassword(user: string, password: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postTicket({
      DSPAccount: {
        AppId: this.appId,
        ClientId: CONFIG.dspClientId,
        DeviceId: await this.getDSPGuestId(),
        Username: user,
        Password: password
      }
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  public async loginWithDSPAccessToken(accessToken: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postTicket({
      DSPAccount: {
        AppId: this.appId,
        ClientId: CONFIG.dspClientId,
        DeviceId: await this.getDSPGuestId(),
        AccessToken: accessToken
      }
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  private createDSPSocialAuth(policy: string): SalteAuth {
    return new SalteAuth({      
      providers: [
        new LaLigaDSPProvider({
          baseURL: 'https://prelogin.laliga.es',
          tenantID: 'LaLigaDspPreB2C.onmicrosoft.com',
          clientID: CONFIG.dspClientId,
          policy,
        })
      ],
      handlers: [ new Tab({ default: true }) ]
    });
  }

  private async doDSPSocialLogin(policy: string): Promise<SSOResult> {
    const auth = this.createDSPSocialAuth(policy);
    const result = await new Promise<SalteAuth.EventWrapper>((resolve, reject) => {
      auth.on('login', (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
      auth.login('laliga-dsp');
    });
    const provider = auth.provider(result.provider) as LaLigaDSPProvider;
    const token = await provider.exchangeCodeForToken();
    return this.loginWithDSPAccessToken(token);
  }

  public async loginWithDSPFacebook(): Promise<SSOResult> {
    return this.doDSPSocialLogin('b2c_1a_signupsignin_fb_nl');
  }

  public async loginWithDSPGoogle(): Promise<SSOResult> {
    return this.doDSPSocialLogin('b2c_1a_signupsignin_gl_nl');
  }

  public async loginWithRayadosUserPassword(email: string, password: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postTicket({
      Rayados: {
        AppId: this.appId,
        Email: email,
        Password: password
      },
      CreateNewAccount: false
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  public redirectToKOREBrandedLogin(loginOrRegister: 'login' | 'register' = 'login') {
    const returnUri = new URL(CONFIG.ssoOAuthRedirectPath, window.location.href)
    let newUri;
    if (sessionStorage.getItem('previousUrl') && sessionStorage.getItem('navigatedToListenLive') === "true") {
      newUri = new URL(sessionStorage.getItem('previousUrl'), window.location.href);
      history.replaceState(null, "", newUri);
    }
    let finalUriParam = sessionStorage.getItem('previousUrl') && sessionStorage.getItem('navigatedToListenLive') === "true" ? newUri.href : window.location.href;
    returnUri.searchParams.set(OAUTH_USE_SDK_PARAM, 'false');
    returnUri.searchParams.set(OAUTH_LOGIN_PROVIDER_PARAM, OAUTH_KORE_BRANDED_LOGIN);
    returnUri.searchParams.set(OAUTH_FINAL_URI_PARAM, finalUriParam);

    const loginUri = new URL(`/auth/${loginOrRegister}`, CONFIG.ssoKoreBrandedLoginBaseUrl);
    loginUri.searchParams.set('tenantid', CONFIG.ssoKoreBrandedLoginTenantId);
    loginUri.searchParams.set('returnvisitorurl', finalUriParam);
    loginUri.searchParams.set('successredirecturl', returnUri.toString());

    sessionStorage.removeItem('previousUrl');
    sessionStorage.removeItem('navigatedToListenLive');
    window.location.href = loginUri.toString();
  }

  public async loginWithSportsAllianceTokenPayload(tokenPayload: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postTicket({
      SportsAlliance: {
        AppId: this.appId,
        TokenPayload: tokenPayload
      }
    });
    return this.handleTicketGrantingAPIResponse(rsp);
  }

  public async passwordResetWithDSP(user: string): Promise<SSOResult> {
    let rsp = await this.signonServer.postPasswordReset(this.getStatus().ticket, {
      DSPAccount: {
        AppId: this.appId,
        ClientId: CONFIG.dspClientId,
        DeviceId: await this.getDSPGuestId(),
        Username: user
      }
    });
    if (!rsp || rsp.isStatusNot2xx) {
      return INTERNAL_ERROR;
    }
    return { success: true };
  }

  public getConsentsWithStatus(): ReactiveMicrocomponent<YinzCamAPIResponse> {
    // get or load guest ID
    //let guestId = await this.getDSPGuestId();

    // get consents from config server
    let configLegalComp = this.configServer.getConfigLegal();
    //console.log('GET CONSENTS', rsp);

    // wrap with our current consent status
    let consentStatusComp = new ConsentsWithStatusComponent({ name: 'JanusSignonManager.getConsentsWithStatus', saveToLocalStorage: false }, configLegalComp);

    return consentStatusComp;
  }

  // TODO: probably want this class to manage consentData?
  // this version of the function works with profile fields instead of consent IDs
  public async setConsentStatusOld(consentFlags: { [profileField: string]: boolean }, consentData: { version: string, consents: { id: string, profileField: string}[] }) {
    // store locally
    let consentStatus = { consentsVersion: consentData.version, consentFlags };
    localStorage.setItem('JanusSignonManager:ConsentStatusJson', JSON.stringify(consentStatus));

    // get our guest ID
    let dspGuestId = await this.getDSPGuestId();

    // accept policy
    await this.dspServer.postPoliciesAccept(dspGuestId, consentData.version, new Date());

    // accept consents
    // have to jump through all these hoops because "id" isn't always present on consents...
    let idConsentFlags = Object.fromEntries(consentData.consents.filter(c => c.id).map(c => [ c.id, consentFlags[c.profileField] || false ]));
    await this.dspServer.postConsentsStatus(dspGuestId, idConsentFlags);
  }

  public async setConsentStatus(consentFlags: { [consentId: string]: boolean }, consentData: { version: string, consents: { id: string, profileField: string}[] }) {
    // store locally
    let consentStatus = { consentsVersion: consentData.version, consentFlags };
    localStorage.setItem('JanusSignonManager:ConsentStatusJson', JSON.stringify(consentStatus));

    // get our guest ID
    let dspGuestId = await this.getDSPGuestId();

    try {
      // accept policy
      await this.dspServer.postPoliciesAccept(dspGuestId, consentData.version, new Date());

      // accept consents
      // have to jump through all these hoops because "id" isn't always present on consents...
      await this.dspServer.postConsentsStatus(dspGuestId, consentFlags);
    } catch (e) {
      console.log("Unable to save consents", e);
    }
  }

  // I'm not sure if this even needs to be used?
  public async getDSPGuestId() {
    let guestId = localStorage.getItem('JanusSignonManager:DSPGuestId');
    if (!guestId) {
      // TODO: get country code from some API
      let countryCode = 'es';

      // Request a new guest ID from DSP
      let rsp = await this.dspServer.postRegisterGuest(countryCode, this.appId);
      guestId = (rsp.data as any).guestId;
      localStorage.setItem('JanusSignonManager:DSPGuestId', guestId);
    }
    // Store it in a cookie because LaLiga said so.
    let dt = new Date();
    dt.setTime(dt.getTime() + (365*24*60*60*1000));
    let expires = "; expires=" + dt.toUTCString();
    let hostname = window.location.hostname;
    document.cookie = "_guid=" + guestId + expires + "; domain=" + hostname;
    return guestId;
  }
}

// Modifies a consent response to include local status info
class ConsentsWithStatusComponent extends AbstractReactiveMicrocomponent<YinzCamAPIResponse, [YinzCamAPIResponse]> {
  public constructor(config: ReactiveMicrocomponentConfig<any>, input: ReactiveMicrocomponent<YinzCamAPIResponse>) {
    super(config, input);
  }

  protected async update($control: unknown, $input: YinzCamAPIResponse): Promise<YinzCamAPIResponse> {
    let consentStatusJson = localStorage.getItem('JanusSignonManager:ConsentStatusJson');
    let consentStatus = { consentsVersion: "0.0", consentFlags: {} };
    if (consentStatusJson) {
      consentStatus = JSON.parse(consentStatusJson);
    }
    ($input.data as any).consentStatus = consentStatus;
    return $input;
  }
}

@injectable()
export class JanusSignonManagerParameterComponent extends AbstractReactiveMicrocomponent<YinzCamAPIRequestParameters, [ SignonStatus ]> implements YinzCamAPIRequestParameterComponent {
  public constructor(@injectToken(JanusSignonStateManagerToken) private readonly stateManager: JanusSignonStateManager) {
    super({ name: 'JanusSignonManagerParameterComponent', saveToLocalStorage: false }, stateManager.getStatusComponent());
  }

  protected async update($control: unknown, $status: SignonStatus): Promise<YinzCamAPIRequestParameters> {
    if (!$status?.loggedIn || !$status?.ticket) {
      return {};
    }
    //console.log("JanusSignonManagerParameterComponent dropsTicket: " + $status.dropsTicket);
    let ret: YinzCamAPIRequestParameters = {
      services: {
        signon: {
          headers: {
            'X-YinzCam-Ticket': $status.ticket
          }
        }
      }
    };
    /*
    if ($status.dropsTicket) {
      ret.services.app = {
        headers: {
          'X-YinzCam-Ticket': $status.dropsTicket
        }
      }
    }
    */
    if ($status.serviceTicket) {
      ret.services.app = {
        headers: {
          'X-YinzCam-Ticket': $status.serviceTicket
        }
      }
    }
    return ret;
  }
}

export interface LaLigaDSP {
  config: LaLigaDSP.Config;
}

export declare namespace LaLigaDSP {
  interface Config extends OpenIDProvider.Config {
    baseURL?: string;
    tenantID: string;
    policy: string;
  }
}

export class LaLigaDSPProvider extends OpenIDProvider {
  constructor(config: LaLigaDSP.Config) {
    // fill in defaults
    config = {
      baseURL: 'https://login.microsoftonline.com',
      responseType: 'code',
      redirectUrl: new URL('/', window.location.origin).toString().slice(0, -1),
      ...config
    };
    // compute optional fields, if they are missing
    config = {
      url: `${config.baseURL}/${config.tenantID}/oauth2/v2.0`,
      scope: `openid ${config.clientID} offline_access`,
      ...config
    };
    super(config);
  }

  get name() {
    return 'laliga-dsp';
  }

  get login() {
    return this.url(`${this.config.url}/authorize`, {
      p: this.config.policy,
    });
  }

  get logout() {
    return this.url(`${this.config.url}/logout`, {
      p: this.config.policy,
    });
  }

  get token() {
    return this.url(`${this.config.url}/token`, {
      p: this.config.policy,
    });
  }

  public async exchangeCodeForToken(): Promise<string> {
    if (!this.code) {
      throw new Error('code must be available, call login first');
    }
    const params = new URLSearchParams();
    params.append('client_id', this.config.clientID);
    params.append('scope', this.config.scope);
    params.append('grant_type', 'authorization_code');
    params.append('code', this.code);
    const result = await Axios.post(this.token, params);
    return result.data.access_token;
  }
}