import { sl } from "date-fns/locale";
import { getContext, setContext } from "svelte";
import { Readable, writable, Writable } from "svelte/store";

const ImageNoLazyLoadSubtreeContextKey = {};

const ImageLoadBarrierSubtreeContextKey = {};

const defaultImageLoadBarrierStore = writable(true);

export function setImageNoLazyLoadContext(noLazyLoad: boolean) {
  setContext(ImageNoLazyLoadSubtreeContextKey, noLazyLoad);
}

export function getImageNoLazyLoadContext(): boolean {
  return getContext(ImageNoLazyLoadSubtreeContextKey);
}

export function createSubtreeImageLoadBarrierStore(): Writable<boolean> {
  const s = writable(false);
  setContext(ImageLoadBarrierSubtreeContextKey, s);
  return s;
}

export function getSubtreeImageLoadBarrierStore(): Readable<boolean> {
  return getContext(ImageLoadBarrierSubtreeContextKey) || defaultImageLoadBarrierStore;
}
