<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
import 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import { CardsTabContextKey } from '../../../common/context';
import 'svelte/store';
import { getContext, onMount } from 'svelte';
import moment from 'moment';
import footballWhiteImg from '../image/football_icon_white.png';
import footballBlackImg from '../image/football_icon.png';
import VideoStreamPlayer from '../VideoStreamPlayer/VideoStreamPlayer.svelte';
import WatchTimer from '../WatchTimer/WatchTimer.svelte';
import { windowMetrics as wm } from '../../../../../js/stores';
import 'svelte-i18n';
export let element;
export let sources;
let isPre;
let isLive;
let isFinal;
let footballImg = '';
let videoRef;
let showListenLive = false;
let title = "";
let clockTime = 0;
let endTime = 0;
const tabContext = getContext(CardsTabContextKey);
$: source = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: isDesktop = $wm.width > 1024;
let sourceData = {
    HomeTeam: {},
    AwayTeam: {},
    ScoringPeriod: [],
    HomeScores: [],
    AwayScores: [],
    Clock: {},
    State: ""
};
let submittedGtm = false;
$: {
    if ($source) {
        /*
        if (![$source?.HomeTeam?._attributes?.TriCode?.toLowerCase(), $source?.AwayTeam?._attributes?.TriCode?.toLowerCase()].includes('tot')) {
          window.location.href = 'https://www.tottenhamhotspur.com/'
        }
        */
        sourceData = {
            HomeTeam: {
                FullName: ((_c = (_b = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _b === void 0 ? void 0 : _b._attributes) === null || _c === void 0 ? void 0 : _c.FullName) || "",
                TriCode: ((_e = (_d = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _d === void 0 ? void 0 : _d._attributes) === null || _e === void 0 ? void 0 : _e.TriCode) || "",
                // Logo: `https://resources-uk.yinzcam.com/soccer/shared/logos/${$source?.HomeTeam?._attributes?.LogoId?.toLowerCase()}_${$source?.State?._text == "C" ? "light" : "dark"}.png`
                Logo: ((_f = $source === null || $source === void 0 ? void 0 : $source.State) === null || _f === void 0 ? void 0 : _f._text) == "C" ? (_h = (_g = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _g === void 0 ? void 0 : _g.ImageUrl) === null || _h === void 0 ? void 0 : _h._text : (_k = (_j = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _j === void 0 ? void 0 : _j.ImageUrlDark) === null || _k === void 0 ? void 0 : _k._text,
            },
            AwayTeam: {
                FullName: ((_m = (_l = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.FullName) || "",
                TriCode: ((_p = (_o = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _o === void 0 ? void 0 : _o._attributes) === null || _p === void 0 ? void 0 : _p.TriCode) || "",
                // Logo: `https://resources-uk.yinzcam.com/soccer/shared/logos/${$source?.AwayTeam?._attributes?.LogoId?.toLowerCase()}_${$source?.State?._text == "C" ? "light" : "dark"}.png`
                Logo: ((_q = $source === null || $source === void 0 ? void 0 : $source.State) === null || _q === void 0 ? void 0 : _q._text) == "C" ? (_s = (_r = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _r === void 0 ? void 0 : _r.ImageUrl) === null || _s === void 0 ? void 0 : _s._text : (_u = (_t = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _t === void 0 ? void 0 : _t.ImageUrlDark) === null || _u === void 0 ? void 0 : _u._text,
            },
            Result: `${(_w = (_v = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _v === void 0 ? void 0 : _v._attributes) === null || _w === void 0 ? void 0 : _w.Score} – ${(_y = (_x = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _x === void 0 ? void 0 : _x._attributes) === null || _y === void 0 ? void 0 : _y.Score}`, //($source?.Result?._text || "").split(" ")[0],
            PenaltyResult: ((_z = $source === null || $source === void 0 ? void 0 : $source.PenaltyResultLong) === null || _z === void 0 ? void 0 : _z._text) || "",
            CompetitionName: ((_1 = (_0 = $source === null || $source === void 0 ? void 0 : $source.Competition) === null || _0 === void 0 ? void 0 : _0._attributes) === null || _1 === void 0 ? void 0 : _1.Name) || "",
            Stadium: ((_2 = $source === null || $source === void 0 ? void 0 : $source.Venue) === null || _2 === void 0 ? void 0 : _2._text) || "",
            ScoringPeriod: []
                .concat((_4 = []
                .concat((_3 = $source === null || $source === void 0 ? void 0 : $source.Scoring) === null || _3 === void 0 ? void 0 : _3.ScoringPeriod)
                .find((e) => { var _a; return ((_a = e === null || e === void 0 ? void 0 : e._attributes) === null || _a === void 0 ? void 0 : _a.MainHeading) === 'SCORING'; })) === null || _4 === void 0 ? void 0 : _4.ScoringPlay)
                .filter(e => e)
                .map((e) => {
                var _a, _b, _c, _d;
                return ({
                    IsHome: JSON.parse((_a = e === null || e === void 0 ? void 0 : e._attributes) === null || _a === void 0 ? void 0 : _a.IsHome),
                    Time: ((_b = e === null || e === void 0 ? void 0 : e._attributes) === null || _b === void 0 ? void 0 : _b.Time) || "",
                    Name: ((_d = (_c = [].concat(e === null || e === void 0 ? void 0 : e.Description)) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d._text) || "",
                });
            }),
            ClockTime: ((_5 = $source === null || $source === void 0 ? void 0 : $source.Clock) === null || _5 === void 0 ? void 0 : _5._text) || "0'",
            GameState: ((_6 = $source === null || $source === void 0 ? void 0 : $source.GameState) === null || _6 === void 0 ? void 0 : _6._text) || "",
            State: (_7 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _7 === void 0 ? void 0 : _7._text,
            DateValue: new Date((_8 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _8 === void 0 ? void 0 : _8._text)
        };
        isPre = ((_9 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _9 === void 0 ? void 0 : _9._text) === 'P';
        isLive = ((_10 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _10 === void 0 ? void 0 : _10._text) === 'C';
        isFinal = ((_11 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _11 === void 0 ? void 0 : _11._text) === 'F';
        footballImg = isLive ? footballBlackImg : footballWhiteImg;
        showListenLive = (_12 = $source === null || $source === void 0 ? void 0 : $source.ShowListenLive) === null || _12 === void 0 ? void 0 : _12._text;
        sourceData.HomeScores = sourceData.ScoringPeriod.filter(item => item.IsHome);
        sourceData.AwayScores = sourceData.ScoringPeriod.filter(item => !item.IsHome);
        title = `${sourceData.HomeTeam.TriCode} vs ${sourceData.AwayTeam.TriCode} - ${sourceData.DateValue.getDate().toString().padStart(2, "0")}/${(sourceData.DateValue.getMonth() + 1).toString().padStart(2, "0")}`;
    }
}
$: endTime = ((_13 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _13 === void 0 ? void 0 : _13._text) ? (new Date((_14 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _14 === void 0 ? void 0 : _14._text)).getTime() : 0;
$: clockTime = endTime ? (endTime - (new Date()).getTime()) / 1000 : 0;
$: gameId = ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.gameId) || "";
$: {
    const prefix = "Laliga_Tot_";
    const competitionName = (_16 = (_15 = $source === null || $source === void 0 ? void 0 : $source.Competition) === null || _15 === void 0 ? void 0 : _15._attributes) === null || _16 === void 0 ? void 0 : _16.Name;
    const homeName = (_18 = (_17 = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _17 === void 0 ? void 0 : _17._attributes) === null || _18 === void 0 ? void 0 : _18.Name;
    const awayName = (_20 = (_19 = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _19 === void 0 ? void 0 : _19._attributes) === null || _20 === void 0 ? void 0 : _20.Name;
    const timeStamp = (_21 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _21 === void 0 ? void 0 : _21._text;
    window.localStorage.setItem(prefix + "Competition_Name", competitionName);
    window.localStorage.setItem(prefix + "Home_Name", homeName);
    window.localStorage.setItem(prefix + "Away_Name", awayName);
    window.localStorage.setItem(prefix + "Timestamp", timeStamp);
    if (!submittedGtm && competitionName) {
        submittedGtm = true;
        setTimeout(() => {
            var _a;
            (_a = window === null || window === void 0 ? void 0 : window['dataLayer']) === null || _a === void 0 ? void 0 : _a.push({
                event: 'match_details_viewed',
                login_status: 'guest', // One of: logged_in, guest
                crn: null, // CRN number of the user. Null if unavailable
                customer_id: null, // Unique customer ID, provided by SSO. Null if unavailable
                topics_followed: null, //List of topics (tags) followed by the user. Null if unavailable
                scope: 'THFC Website',
                source: 'www.tottenhamhotspur.com',
                page_language: 'en',
                page_url: location.href,
                page_title: document.title,
                page_category: 'Match Centre',
                match_id: gameId,
                match_name: homeName + ' - ' + awayName, // Name of the match. Consists of {match_host_team} - {match_opponent_team}
                match_kickoff_datetime: moment(new Date(timeStamp)).format("YYYY-MM-DD HH:mm"), // Kickoff datetime of the match. Format: YYYY-MM-DD HH:MM
                team_squad: 'men', // [ men, women, U23, U18 ]
                competition_name: competitionName, // Name of the competition. Example(s): Premier League, Carabao Cup
                match_host_team: homeName, // Name of the team that has hosted the match. Example(s): Tottenham Hotspur
                match_opponent_team: awayName, // Name of the opponent team. Example(s): Arsenal
                details_type: 'commentary' // [ 'commentary', 'lineups', 'stats', 'live_results' ]
            });
        }, 1000);
    }
}
// Google Tag Manager
function initialize() {
    (function (wnd, doc, scriptTag, dataLayer, gtmId) {
        wnd[dataLayer] = wnd[dataLayer] || [];
        wnd[dataLayer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const firstScript = doc.getElementsByTagName(scriptTag)[0];
        const newScript = doc.createElement(scriptTag);
        const dataLayerStr = dataLayer !== 'dataLayer' ? '&l=' + dataLayer : '';
        newScript.async = true;
        newScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dataLayerStr;
        firstScript.parentNode.insertBefore(newScript, firstScript);
    })(window, document, 'script', 'dataLayer', 'GTM-KFB6C9R');
}
// Google Tag Manaer
onMount(() => {
    initialize();
    window['dataLayer'].push({
        event: 'page_viewed',
        login_status: 'guest', // One of: logged_in, guest
        crn: null, // CRN number of the user. Null if unavailable
        customer_id: null, // Unique customer ID, provided by SSO. Null if unavailable
        topics_followed: null, //List of topics (tags) followed by the user. Null if unavailable
        scope: 'THFC Website',
        source: 'www.tottenhamhotspur.com',
        page_language: 'en',
        page_url: location.href,
        page_title: document.title,
        page_category: 'Match Centre'
    });
});
</script>

<CardBase {element}>
  <div class="match-panel" class:match-panel-dark={!isLive}>
    <div class="match-score-box" class:display-none={!$source}>
      <div class="goal-container">
        {#each sourceData.ScoringPeriod as player}
          {#if player.IsHome}
            <div class="goal-item">
              <span class="player">{player.Name || ""} {player.Time || ""}</span>
              <img src={footballImg} alt="football" class="ball" />
            </div>
          {/if}
        {/each}
      </div>
      <div class="match-team">
        <div
          class="team"
          style={'margin-bottom: 0'}
        >
          <img src={sourceData.HomeTeam.Logo || ""} alt="logo" class="logo" />
          <div class="name">{sourceData.HomeTeam.FullName || ""}</div>
          <div class="short-name">{sourceData.HomeTeam.TriCode || ""}</div>
        </div>
        <div class="match-info">
          <h3>{sourceData.CompetitionName || ""}</h3>
          {#if isLive || isFinal}
            <div class="match-date">
              <!-- <span class="date">Wednewday, 7 April</span>
              <span class="time">18:00</span> -->
              <span class="date">{moment(sourceData.DateValue).format("DD MMM YYYY")}</span>
            </div>
          {/if}
          {#if isPre}
            <div class="match-date">
              <span class="date">{moment(sourceData.DateValue).format("DD MMM YYYY")}</span>
              <span class="time">{moment(sourceData.DateValue).format("HH:mm")}</span>
            </div>
          {:else}
            <div class="score-info">
              {sourceData.Result}
            </div>
            {#if isLive}
              <div class="progress-time">
                <div class="circle-mark" />
                <div class="info">{sourceData.GameState || ""}</div>
                <div class="time">{sourceData.ClockTime || ""}</div>
              </div>
            {:else}
              <div class="match-over-info">{sourceData.PenaltyResult === "" ? sourceData.GameState : sourceData.PenaltyResult}</div>
            {/if}
          {/if}
          <a href={encodeURI(`https://www.google.com/maps?q=${sourceData.Stadium}`)} target="_blank" class="external stadium">{sourceData.Stadium || ""}</a>
          {#if isPre && isDesktop}
            <div class="watch-timer">
              <WatchTimer resetTime={clockTime} />
            </div>
          {/if}
        </div>
        <div
          class="team"
          style={'margin-bottom: 0'}
        >
          <img src={sourceData.AwayTeam.Logo || ""} alt="logo" class="logo" />
          <div class="name">{sourceData.AwayTeam.FullName || ''}</div>
          <div class="short-name">{sourceData.AwayTeam.TriCode || ''}</div>
        </div>
      </div>
      <div class="goal-container away">
        {#each sourceData.ScoringPeriod as player}
          {#if !player.IsHome}
            <div class="goal-item">
              <span class="player">{player.Name || ''} {player.Time || ''}</span>
              <img src={footballImg || ''} alt="football" class="ball" />
            </div>
          {/if}
        {/each}
      </div>
    </div>

    {#if isPre && !isDesktop}
      <div class="watch-timer">
        <WatchTimer resetTime={clockTime} />
        <!--<div class="description">until kick-off</div>-->
      </div>
    {/if}
    <!-- <GTM gtmId='GTM-KFB6C9R' /> -->

    {#if showListenLive}
      <div class="play-button">
        <VideoStreamPlayer bind:this={videoRef} />
      </div>
    {/if}
  </div>
</CardBase>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

:root {
  --goal-players-margin: 0px; }

@media (max-width: 991px) {
  :root {
    --goal-players-margin: 15px; }
  .match-panel .watch-timer {
    margin: 10px 0 0 0; } }

.match-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*height: 281px;*/
  position: relative;
  /*background-color: var(--theme-primary-background-color, white);*/
  --primary-match-text-color: var(--theme-primary-foreground-color, black);
  padding-bottom: 30px; }
  .match-panel.match-panel-dark {
    /*background-color: var(--theme-inverted-background-color, black);*/
    --primary-match-text-color: var(--theme-inverted-foreground-color, white); }

.match-score-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-match-text-color); }
  .match-score-box .match-team {
    display: flex;
    align-items: flex-start;
    justify-content: center; }
  .match-score-box .team {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 0 0 0;
    flex-basis: min-content; }
    .match-score-box .team .logo {
      height: 175px; }
    .match-score-box .team .name {
      /*height: 22px;*/
      margin: 6px 0 0 0;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.57;
      text-align: center;
      text-transform: uppercase;
      text-wrap: balance; }
    .match-score-box .team .short-name {
      display: none; }
  .match-score-box .match-info {
    margin: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .match-score-box .match-info h3 {
      /*height: 21px;*/
      margin: 0 0 20px 0;
      font-size: 14.6px;
      font-weight: 500;
      line-height: 1.57;
      text-align: center; }
    .match-score-box .match-info .match-date {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0px 0 8px 0; }
      .match-score-box .match-info .match-date .date {
        /*height: 15px;*/
        margin: 0;
        font-size: 14.6px;
        line-height: 0.94;
        text-align: center; }
      .match-score-box .match-info .match-date .time {
        /*height: 22px;*/
        margin: 8px 0 0 0;
        font-size: 28px;
        font-weight: 600;
        line-height: 0.79;
        letter-spacing: -0.64px;
        text-align: center; }
    .match-score-box .match-info .score-info {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 81px;
      margin: 0px;
      font-family: Arial;
      font-size: 72.4px;
      font-weight: bold;
      color: var(--primary-match-text-color);
      width: 170px; }
      .match-score-box .match-info .score-info .score1,
      .match-score-box .match-info .score-info .score2 {
        width: 80px;
        text-align: center;
        display: block; }
      .match-score-box .match-info .score-info .vs {
        line-height: 1;
        margin: 0 10px 10px 10px;
        display: block; }
    .match-score-box .match-info .match-over-info {
      text-transform: uppercase;
      /*height: 30px;*/
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      color: var(--primary-match-text-color); }
    .match-score-box .match-info .stadium {
      /*height: 22px;*/
      margin: 4px 0 0 0;
      font-size: 14px;
      line-height: 1.57;
      text-align: center;
      color: var(--primary-match-text-color);
      -webkit-text-decoration: none;
      text-decoration: none; }
    .match-score-box .match-info .watch-timer {
      margin: 30px 0 0 0; }
  .match-score-box .progress-time {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-match-text-color);
    text-transform: uppercase;
    gap: 10px; }
    .match-score-box .progress-time .circle-mark {
      border-radius: 50%;
      border: 3px solid var(--theme-primary-accent-color, black);
      width: 21px;
      height: 21px;
      position: relative; }
      .match-score-box .progress-time .circle-mark:after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        background: var(--theme-primary-accent-color, black);
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 50%; }
  .match-score-box .goal-container {
    margin: 0 var(--goal-players-margin) 0 0;
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 4px; }
    .match-score-box .goal-container .goal-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0; }
      .match-score-box .goal-container .goal-item:last-child {
        margin-bottom: 0; }
      .match-score-box .goal-container .goal-item * {
        display: block; }
      .match-score-box .goal-container .goal-item .player {
        margin: 0 13px 0 0;
        line-height: 22px;
        font-size: 14px;
        color: var(--primary-match-text-color);
        text-align: right; }
      .match-score-box .goal-container .goal-item .ball {
        width: 18px;
        height: 18px; }
    .match-score-box .goal-container.away {
      margin: 0 0 0 var(--goal-players-margin); }
      .match-score-box .goal-container.away .goal-item {
        flex-direction: row-reverse; }
        .match-score-box .goal-container.away .goal-item .player {
          margin: 0 0 0 13px;
          text-align: left; }

.play-button {
  position: absolute;
  right: 20px;
  bottom: 0px; }

@media (max-width: 767px) {
  .match-panel {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: unset;
    position: relative; }
    .match-panel .symbol {
      display: none; }
    .match-panel .watch-timer {
      margin: 0 0 0 0; }
      .match-panel .watch-timer .description {
        font-size: 10px;
        text-align: center;
        color: var(--theme-primary-foreground-color, black);
        /*color: var(--theme-inverted-foreground-color, white);*/
        margin: 8px 0 16px 0; }
  .match-score-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-match-text-color); }
    .match-score-box .match-team {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin: 12px 0 15.7px 0; }
      .match-score-box .match-team .team {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 0 0 0; }
        .match-score-box .match-team .team .logo {
          height: 50px; }
        .match-score-box .match-team .team .name {
          display: none;
          text-transform: uppercase; }
        .match-score-box .match-team .team .short-name {
          display: block;
          line-height: 16px;
          margin: 0;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          color: var(--primary-match-text-color);
          text-transform: uppercase; }
      .match-score-box .match-team .match-info {
        margin: 0 11.4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .match-score-box .match-team .match-info h3 {
          line-height: 15.1px;
          font-size: 12px;
          order: 1;
          margin: 0 -30px 10.9px -30px; }
        .match-score-box .match-team .match-info .match-date {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0px 0 8px 0;
          order: 3; }
          .match-score-box .match-team .match-info .match-date .date {
            /*height: 15px;*/
            margin: 0;
            font-size: 12px;
            line-height: 0.94;
            text-align: center; }
          .match-score-box .match-team .match-info .match-date .time {
            /*height: 22px;*/
            margin: 8px 0 0 0;
            font-size: 20.3px;
            font-weight: 600;
            line-height: 0.79;
            letter-spacing: -0.64px;
            text-align: center; }
        .match-score-box .match-team .match-info .score-info {
          line-height: 43px;
          font-size: 39.1px;
          order: 5;
          width: unset; }
          .match-score-box .match-team .match-info .score-info .score1,
          .match-score-box .match-team .match-info .score-info .score2 {
            width: 45px;
            text-align: center;
            display: block; }
          .match-score-box .match-team .match-info .score-info .vs {
            line-height: 1;
            margin: 0 10px 5px 10px;
            display: block; }
        .match-score-box .match-team .match-info .match-over-info {
          text-transform: uppercase;
          /*height: 16.2px;*/
          font-size: 10.8px;
          line-height: 16.2px;
          text-align: center;
          color: var(--primary-match-text-color);
          margin: 0 0 2px 0;
          order: 3; }
        .match-score-box .match-team .match-info .stadium {
          /*height: 16px;*/
          margin: 0 -30px 0 -30px;
          font-size: 10.2px;
          line-height: 1.57;
          order: 7;
          visibility: hidden; }
        .match-score-box .match-team .match-info .progress-time {
          margin: 0 0 6px 0;
          line-height: 17px;
          font-size: 10.8px;
          order: 3;
          gap: 4px; }
          .match-score-box .match-team .match-info .progress-time .circle-mark {
            border-radius: 50%;
            border: 3px solid var(--theme-primary-accent-color, black);
            width: 11px;
            height: 11px;
            position: relative; }
            .match-score-box .match-team .match-info .progress-time .circle-mark:after {
              content: '';
              display: block;
              width: 5px;
              height: 5px;
              background: var(--theme-primary-accent-color, black);
              position: absolute;
              left: 0px;
              top: 0px;
              border-radius: 50%; }
          .match-score-box .match-team .match-info .progress-time .info {
            margin: 0 8px 0 5px; }
    .match-score-box .goal-container {
      margin: 0;
      padding: 0;
      width: 90px; }
      .match-score-box .goal-container .goal-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0; }
        .match-score-box .goal-container .goal-item:last-child {
          margin-bottom: 0; }
        .match-score-box .goal-container .goal-item * {
          display: block; }
        .match-score-box .goal-container .goal-item .player {
          margin: 0 12px 0 0;
          line-height: 16px;
          font-size: 9.7px; }
        .match-score-box .goal-container .goal-item .ball {
          width: 12px;
          height: 12px;
          display: none; }
      .match-score-box .goal-container.away {
        margin: 0 0 0 -1px; }
        .match-score-box .goal-container.away .goal-item {
          flex-direction: row-reverse; }
          .match-score-box .goal-container.away .goal-item .player {
            margin: 0 0 0 11px; }
  .play-button {
    position: absolute;
    right: 10px;
    bottom: 0px; } }

@media (max-width: 395px) {
  .match-panel .match-score-box .goal-container {
    display: none; } }</style>
