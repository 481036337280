import { generateBackgroundSchema, generateBorderSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, RichTextParticle, RichTextParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Profile Form", generateRootObjectSchema({
  container: generateAtomContainerSchema({ defaultValues: {
    themeMode: 'primary'
  }}),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text.", {
    defaultValues: {
      text: 'Account Settings',
      useAccentColor: true,
      size: '2.25rem',
      weight: '800',
      container: {
        centerContentHorizontally: false
      }
    }
  }),
  outerBoxPadding: generateStringSchema("Outer Box Padding", "The padding for the outer box.", { defaultValue: '1.25rem 2.63rem 2.63rem 2.63rem' }),
  outerBoxBackgroundColor: generateStringSchema("Inner Box Background Color", "The background color for the outer box.", { defaultValue: '#EAEAEA' }),
  outerBoxBorderRadius: generateStringSchema("Outer Box Border Radius", "The border radius for the outer box.", { defaultValue: '3rem' }),
  titleRowPadding: generateStringSchema("Title Row Padding", "The padding for the title row.", { defaultValue: '0rem 4.25rem 1rem 4.25rem' }),
  innerBoxPadding: generateStringSchema("Inner Box Padding", "The padding for the inner box.", { defaultValue: '5rem 2.5rem' }),
  innerBoxBackgroundColor: generateColorSchema("Inner Box Background Color", "The background color for the inner box.", { defaultValue: '#FFFFFF' }),
  innerBoxBorderRadius: generateStringSchema("Inner Box Border Radius", "The border radius for the inner box.", { defaultValue: '3rem' }),
  innerBoxDividerColor: generateColorSchema("Inner Box Divider Color", "The divider color within the inner box.", { defaultValue: '#EAEAEA' }),
  // leftSideWidth: generateStringSchema("Left Side Width", "The width of the left side button area.", { defaultValue: '21.875rem' }),
  leftSideActiveButtonTemplate: ButtonParticleSpec.generateSchema("Section Active Buttons", "The styling template for the left side active buttons.", {
    defaultValues: {
      showImage: false,
      container: {
        padding: '0.625rem 1.75rem 0.5rem 1.75rem',
        border: {
          visible: true,
          radius: '2.5rem'
        },
        background: {
          visible: true,
          color: '#EAEAEA'
        },
        width: 'min-content'
      },
      text: {
        useAccentColor: true,
        size: '1.5rem',
        weight: '500'
      },
      justification: 'start'
    }
  }),
  leftSideInactiveButtonTemplate: ButtonParticleSpec.generateSchema("Section Inactive Buttons", "The styling template for the left side inactive buttons.", {
    defaultValues: {
      showImage: false,
      container: {
        padding: '0.625rem 1.75rem 0.5rem 1.75rem',
        border: {
          visible: true,
          radius: '2.5rem'
        },
        background: {
          visible: true,
          color: 'white'
        },
        width: 'min-content'
      },
      text: {
        color: '#636466',
        size: '1.5rem',
        weight: '500'
      },
      justification: 'start'
    }
  }),
  leftRightGap: generateStringSchema("Left Right Gap", "The gap between the left and right side areas.", { defaultValue: '3rem' }),
  formBlockGap: generateStringSchema("Form Block Gap", "The gap between form blocks.", { defaultValue: '3.5rem' }),
  profileImageTemplate: ImageParticleSpec.generateSchema("Profile Image", "The template for the profile image.", {
    defaultValues: {
      alt: 'profile image',
      container: {
        width: '7.6475rem',
        height: '7.6475rem',
        border: {
          visible: true,
          radius: '50%'
        },
        overflow: 'hidden'
      },
    }
  }),
  formBlockTitleTextTemplate: TextParticleSpec.generateSchema("Form Block Title Text", "The template for form block title text.", {
    defaultValues: {
      useAccentColor: true,
      size: '1.5rem',
      weight: '500',
      container: {
        centerContentHorizontally: false
      }
    }
  }),
  formFieldTitleTextTemplate: TextParticleSpec.generateSchema("Form Field Title Text", "The template for form field title text.", {
    defaultValues: {
      size: '1.125rem',
      weight: '400',
      container: {
        centerContentHorizontally: false
      }
    }
  }),
  formFieldDescriptionTextTemplate: RichTextParticleSpec.generateSchema("Form Field Description Text", "The template for form field description text.", {
    defaultValues: {      
      textDefaults: {
        color: '#636466',
        size: '1rem',
        weight: '400',
        style: 'italic',
      },
      container: {
        centerContentHorizontally: false
      }
    }
  }),
  formFieldValueTextTemplate: TextParticleSpec.generateSchema("Form Field Value Text", "The template for form field value text.", {
    defaultValues: {
      color: '#636466',
      size: '1.125rem',
      weight: '700',
      container: {
        width: '20rem',
        centerContentHorizontally: false,
        border: { visible: true, color: '#EAEAEA', style: 'solid', width: '0px 0px 1px 0px' }
      },
      minHeight: '1.6rem',
    }
  }),
  formBlockTitlePadding: generateStringSchema("Form Block Title Padding", "The padding for form block titles.", { defaultValue: '1.5rem 0rem 0rem 0rem' }),
  formTitleGap: generateStringSchema("Form Title Gap", "The gap between a form block title and form field area.", { defaultValue: '2.94rem' }),
  formGridRowGap: generateStringSchema("Form Grid Row Gap", "The gap between rows in form grids.", { defaultValue: '2.94rem' }),
}));
