<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "svelte";
import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import { applyImageTransformation, getImageTransformationSlug, rewriteExternalCDNURL } from "components/cards/utilities";
import { t } from "js/i18n";
export let container;
export let overlayPadding;
export let headerTab;
export let playerStarterStats = undefined;
export let playerSubStats = undefined;
export let imgTeamLogo;
export let txtStartersHeading;
export let txtSubHeading;
export let playerImage;
export let txtTeamName;
export let txtLineUp;
export let headerTabs;
export let teamTricode;
export let numberColor;
export let homeTeamPlayerImages;
export let awayTeamPlayerImages;
let atomWidthRem;
let homeTeamImages = [];
let awayTeamImages = [];
let positionOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];
function handleImageError(event) {
    if (!event.target.src.includes("silhouette")) {
        event.target.src =
            "https://resources-au.yinzcam.com/apl/shared/headshots/silhouette.png";
    }
}
function preloadImages(imagesArray) {
    return imagesArray === null || imagesArray === void 0 ? void 0 : imagesArray.map((src) => {
        src = rewriteExternalCDNURL(src);
        const xfSlug = getImageTransformationSlug('general_tiny', 1, 1);
        src = applyImageTransformation(src, xfSlug);
        const img = new Image();
        img.src = src;
        return img;
    });
}
function sortPlayerStats(playerStats) {
    if (playerStats) {
        return playerStats === null || playerStats === void 0 ? void 0 : playerStats.sort((a, b) => {
            let positionValue = positionOrder.indexOf(a.playingPosition.text) -
                positionOrder.indexOf(b.playingPosition.text);
            if (positionValue === 0) {
                return (parseInt(a.position.text) - parseInt(b.position.text));
            }
            return positionValue;
        });
    }
    return [];
}
$: isMobile = atomWidthRem <= 58;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 58;
$: playerImageMultiplier = isMobile ? "1.00" : isTablet ? "0.7" : "1.00";
$: eventIconMultiplier = isMobile ? "0.75" : isTablet ? "0.6" : "0.9";
$: if (homeTeamPlayerImages && !(homeTeamImages === null || homeTeamImages === void 0 ? void 0 : homeTeamImages.length)) {
    homeTeamImages = preloadImages(homeTeamPlayerImages);
}
$: if (awayTeamPlayerImages && !(awayTeamImages === null || awayTeamImages === void 0 ? void 0 : awayTeamImages.length)) {
    awayTeamImages = preloadImages(awayTeamPlayerImages);
}
$: playerStarterStats = sortPlayerStats(playerStarterStats);
$: playerSubStats = sortPlayerStats(playerSubStats);
//${$t('Squad')}
</script>

<BaseAtom
    _isotope="MCLineupTable2Atom"
    _cssVariablePrefix="mc-lineup-table"
    {...container}
    {overlayPadding}
    {numberColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
    >
        <div class="team-name-logo">
            <ImageParticle {...imgTeamLogo} _nolazy={true} role="general_small" />
            <div class="team-name-heading">
                <TextParticle
                    {...txtTeamName}
                    text={`${txtTeamName?.text} ${txtLineUp?.text || 'Line-up'}`}
                    useAccentColor={CONFIG.tricode.toLowerCase() ===
                        teamTricode?.toLowerCase()} 
                />
            </div>
        </div>
        <div class="starters-lineup">
            {#each playerStarterStats || [] as stat}
                <div class="player-card">
                    <div class="player-card-image">
                        <ImageParticle
                            {...playerImage}
                            source={stat?.playerImageUrl}
                            {handleImageError}
                            _nolazy={true}
                            role="general_tiny"
                        />
                    </div>
                    <div class="player-name-position">
                        <TextParticle {...stat?.playerName} />
                        <TextParticle {...stat?.playingPosition} />
                    </div>
                    <div class="player-number">
                        <TextParticle
                            {...stat?.position}
                            useAccentColor={CONFIG.tricode.toLowerCase() ===
                                teamTricode?.toLowerCase()}
                        />
                    </div>
                </div>
            {/each}
        </div>
        <div class="subs-section">
            {#if playerSubStats && playerSubStats[0]?.playerName?.text}
                <div class="sub-heading">
                    <TextParticle
                        {...txtSubHeading}
                        useAccentColor={CONFIG.tricode.toLowerCase() ===
                            teamTricode?.toLowerCase()}
                    />
                </div>
                <div class="subs-lineup">
                    {#each playerSubStats || [] as stat}
                        <div class="player-card">
                            <div class="player-card-image">
                                <ImageParticle
                                    {...playerImage}
                                    source={stat?.playerImageUrl}
                                    {handleImageError}
                                    _nolazy={true}
                                    role="general_tiny"
                                />
                            </div>
                            <div class="player-name-position">
                                <TextParticle {...stat?.playerName} />
                                <TextParticle {...stat?.playingPosition} />
                            </div>
                            <div class="player-number">
                                <TextParticle
                                    {...stat?.position}
                                    useAccentColor={CONFIG.tricode.toLowerCase() ===
                                        teamTricode?.toLowerCase()}
                                />
                            </div>
                        </div>
                    {/each}
                </div>
            {/if}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
    }
    .team-name-logo {
        width: 100%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 1rem;
    }
    .team-name-logo .team-name-heading {
            display: flex;
            flex-direction: column;
        }
    .starters-lineup,
    .subs-lineup {
        width: 100%;
        border-top: 1px solid #fff;
        border-radius: 0.5rem;
        background-color: #fff;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    .player-card {
        display: grid;
        grid-template-columns: 1.5fr 10fr 1fr;
        padding: 0 0.5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        align-items: center;
        border-bottom: 1px solid #eee;
    }
    .player-card .player-name-position {
            display: flex;
            flex-direction: column;
        }
    .player-card .player-number {
            text-align: center;
        }
    .sub-heading {
        padding: 1rem;
    }
    .player-card-image {
        display: flex;
    }
    .player-card-image > :global(*) {
            align-self: flex-end;
        }</style>
