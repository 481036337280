<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import _ from "lodash";
import { BaseAtom } from "components/cards/atoms";
import "components/cards/particles/YinzCam";
import "./ArticleBodyNode";
import ArticleBodyNodeWrapper from "./ArticleBodyNodeWrapper.svelte";
export let container = undefined;
export let textDefaults = undefined;
export let imageDefaults = undefined;
export let videoDefaults = undefined;
export let galleryDefaults = undefined;
export let audioDefaults = undefined;
export let webEmbedDefaults = undefined;
export let captionDefaults = undefined;
export let justifyContent = undefined;
export let body = undefined;
</script>

<style>
  .article-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    text-align: var(--article-body-justify-content, unset);
  }</style>

<BaseAtom _isotope="ArticleBodyAtom" {...container} _cssVariablePrefix="article-body" justifyContent={justifyContent?.toLowerCase()}>
  <div class="article-container">
  {#if _.isArray(body)}
  {#each body as node}
    <ArticleBodyNodeWrapper {node} {textDefaults} {imageDefaults} {videoDefaults} {galleryDefaults} {audioDefaults} {webEmbedDefaults} {captionDefaults} />
  {/each}
  {/if}
  </div>
</BaseAtom>
